import React, { useEffect, useState } from 'react';
import queryHooks from '../../common/queryHooks/index.ts';
import { Link } from 'react-router-dom';

const NotSubscribed = () => {
  const { data: user } = queryHooks.user.useReadOneById({ enabled: false });
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  useEffect(() => {
    const isSuperAdmin = Boolean(user && user.id === user.customer.adminId);
    setIsSuperAdmin(isSuperAdmin);
  }, [user]);

  return (
    <div style={{ marginTop: '40px' }} className="row">
      <h5 className="center">
        You are not subscribed to receive recall alerts
      </h5>
      {isSuperAdmin ? (
        <h6 className="center">
          You can purchase a recall alert subscription from NRAC on the{' '}
          <Link to="/subscription/modify">modify subscription page</Link>
        </h6>
      ) : (
        <h6 className="center">
          Contact your administrator about subscribing for recall alerts from
          NRAC!
        </h6>
      )}
    </div>
  );
};

export default NotSubscribed;
