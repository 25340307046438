import endpoints from '../endpoints.ts';
import {
  FSAccount,
  FSItem,
  FSProduct,
  FSSubscription,
} from '../types/fastspringModels.ts';
import { fetch } from './helpers.ts';

export namespace FastspringAPI {
  export interface FSSessionRequest {
    account: string;
    items: FSItem[];
  }
  export interface FSSessionResponse {
    id: string;
    currency: string;
    expires: number;
    order: null;
    account: string;
    subtotal: number;
    items: FSItem[];
  }
  export interface FSSubscriptionCancelation {
    subscription: string;
    action: string;
    result: string;
  }
}

const fastspring = {
  create: {
    cart: async (
      data: FastspringAPI.FSSessionRequest
    ): Promise<FastspringAPI.FSSessionResponse | null> => {
      const url = endpoints.fastspring.create.cart();
      return await fetch<FastspringAPI.FSSessionResponse>(url, 'POST', data);
    },
  },
  read: {
    one: {
      account: {
        by: {
          userId: async (): Promise<FSAccount[] | null> => {
            const url = endpoints.fastspring.read.one.account.by.userId();
            return await fetch<FSAccount[]>(url, 'GET');
          },
        },
      },
    },
    all: {
      subscriptions: {
        by: {
          userId: async (): Promise<FSSubscription[] | null> => {
            const url = endpoints.fastspring.read.all.subscriptions.by.userId();
            return await fetch<FSSubscription[]>(url, 'GET');
          },
        },
      },
      products: async (): Promise<FSProduct[] | null> => {
        const url = endpoints.fastspring.read.all.products();
        return await fetch<FSProduct[]>(url, 'GET');
      },
    },
  },
  update: {},
  cancel: {
    one: {
      subscription: {
        by: {
          id: async (
            id: string
          ): Promise<FastspringAPI.FSSubscriptionCancelation[] | null> => {
            const url = endpoints.fastspring.cancel.one.subscription.by.id();
            return await fetch<FastspringAPI.FSSubscriptionCancelation[]>(
              url,
              'DELETE',
              {
                subscriptionId: id,
              }
            );
          },
        },
      },
    },
  },
};

export default fastspring;
