//Lets keep these in alphabetical order.
//Easier to find that way.

const APIV1 = '/api/v1';

const endpoints = {
  customer: {
    create: {},
    read: {
      one: {
        by: {
          userId: () => `${APIV1}/customer/read/one/by/userId`,
        },
      },
      activeUserCount: {
        by: {
          customerId: () =>
            `${APIV1}/customer/read/activeusercount/by/customerid`,
        },
      },
    },
    update: {},
    delete: {},
  },
  customerLocationGlobalLocationNumber: {
    create: {
      one: () => `${APIV1}/customerlocationgloballocationnumber/create/one`,
    },
    read: {
      many: {
        by: {
          userId: () =>
            `${APIV1}/customerlocationgloballocationnumber/read/many/by/userid`,
        },
      },
    },
    update: {
      one: () => `${APIV1}/customerlocationgloballocationnumber/update/one`,
    },
    delete: {
      one: () => `${APIV1}/customerlocationgloballocationnumber/delete/one`,
    },
  },
  customerLocation: {
    create: {
      one: () => `${APIV1}/customerlocation/create/one`,
      address: () => `${APIV1}/customerlocation/create/address`,
    },
    read: {
      one: {
        by: {
          id: () => `${APIV1}/customerlocation/read/one/by/id`,
        },
        hydrated: {
          by: {
            id: () => `${APIV1}/customerlocation/read/one/hydrated/by/id`,
          },
        },
      },
      all: {
        by: {
          userId: () => `${APIV1}/customerlocation/read/all/by/userid`,
        },
        hydrated: {
          by: {
            userId: () =>
              `${APIV1}/customerlocation/read/all/hydrated/by/userid`,
          },
        },
      },
    },
    update: {
      one: {
        address: () => `${APIV1}/customerlocation/update/one/address`,
        name: () => `${APIV1}/customerlocation/update/one/name`,
      },
    },
    delete: {
      one: {
        address: () => `${APIV1}/customerlocation/delete/one/address`,
      },
    },
  },
  state: {
    read: {
      all: () => `${APIV1}/state/read/all`,
    },
  },
  fastspring: {
    create: {
      cart: () => `${APIV1}/fastspring/create/cart`,
    },
    read: {
      one: {
        account: {
          by: {
            userId: () => `${APIV1}/fastspring/read/one/account/by/userid`,
          },
        },
      },
      all: {
        subscriptions: {
          by: {
            userId: () =>
              `${APIV1}/fastspring/read/all/subscriptions/by/userid`,
          },
        },
        products: () => `${APIV1}/fastspring/read/all/products`,
      },
    },
    update: {},
    cancel: {
      one: {
        subscription: {
          by: {
            id: () => `${APIV1}/fastspring/cancel/one/subscription/by/id`,
          },
        },
      },
    },
  },
  illegitReport: {
    create: {
      one: () => `${APIV1}/illegitreport/create/one`,
      send: () => `${APIV1}/illegitreport/create/send`,
    },
    download: {
      one: {
        by: {
          path: (): string => `${APIV1}/illegitreport/download/one/by/path`,
        },
      },
    },
    read: {
      many: {
        by: {
          search: () => `${APIV1}/illegitreport/read/many/by/search`,
        },
      },
      count: {
        by: {
          userId: () => `${APIV1}/illegitreport/read/count/by/userid`,
        },
      },
      one: {
        by: {
          id: () => `${APIV1}/illegitreport/read/one/by/id`,
        },
      },
    },
    update: {
      one: {
        by: {
          id: () => `${APIV1}/illegitreport/update/one/by/id`,
        },
      },
    },
    delete: {},
  },
  recall: {
    create: {
      one: {
        dismissedRecall: () => `${APIV1}/nrac/create/one/dismissedrecall`,
      },
    },
    read: {
      one: {
        by: {
          id: () => `${APIV1}/nrac/read/one/by/id`,
        },
        activeRecalledMedCount: {
          by: {
            userId: () =>
              `${APIV1}/nrac/read/one/activerecalledmedcount/by/userid`,
          },
        },
      },
      many: {
        search: () => `${APIV1}/nrac/read/many/search`,
        searchCount: () => `${APIV1}/nrac/read/many/searchcount`,
      },
      all: {
        by: {
          recallCodeIds: () => `${APIV1}/nrac/read/all/by/recallcodeids`,
        },
        activeRecalls: {
          by: {
            recallId: () => `${APIV1}/nrac/read/all/activerecalls/by/recallid`,
            recallCodeIds: () =>
              `${APIV1}/nrac/read/all/activerecalls/by/recallcodeids`,
          },
        },
      },
    },
    update: {
      one: {
        toggleDismissedRecall: () =>
          `${APIV1}/nrac/update/one/toggledismissedrecall`,
      },
    },
  },
  tradingPartner: {
    create: {
      one: () => `${APIV1}/tradingpartner/create/one`,
      tradingPartnerGlobalLocationNumber: () =>
        `${APIV1}/tradingpartner/create/tradingpartnergloballocationnumber`,
      tradingPartnerAddress: () =>
        `${APIV1}/tradingpartner/create/tradingpartneraddress`,
      tradingPartnerContact: () =>
        `${APIV1}/tradingpartner/create/tradingpartnercontact`,
      tradingPartnerContactDetail: () =>
        `${APIV1}/tradingpartner/create/tradingpartnercontactdetail`,
      tradingPartnerAccountNumber: () =>
        `${APIV1}/tradingpartner/create/tradingpartneraccountnumber`,
    },
    read: {
      one: {
        tradingPartner: {
          by: {
            id: () => `${APIV1}/tradingpartner/read/one/tradingpartner/by/id`,
          },
        },
      },
      many: {
        tradingPartners: {
          search: () =>
            `${APIV1}/tradingpartner/read/many/tradingpartners/search`,
        },
      },
    },
    update: {
      one: {
        tradingPartner: () =>
          `${APIV1}/tradingpartner/update/one/tradingpartner`,
        tradingPartnerGlobalLocationNumber: () =>
          `${APIV1}/tradingpartner/update/one/tradingpartnergloballocationnumber`,
        tradingPartnerAddress: () =>
          `${APIV1}/tradingpartner/update/one/tradingpartneraddress`,
        tradingPartnerContact: () =>
          `${APIV1}/tradingpartner/update/one/tradingpartnercontact`,
        tradingPartnerAccountNumber: () =>
          `${APIV1}/tradingpartner/update/one/tradingpartneraccountnumber`,
      },
    },
    delete: {
      one: {
        tradingPartnerGlobalLocationNumber: () =>
          `${APIV1}/tradingpartner/delete/one/tradingpartnergloballocationnumber`,
        tradingPartnerAddress: () =>
          `${APIV1}/tradingpartner/delete/one/tradingpartneraddress`,
        tradingPartnerContact: () =>
          `${APIV1}/tradingpartner/delete/one/tradingpartnercontact`,
        tradingPartnerContactDetail: () =>
          `${APIV1}/tradingpartner/delete/one/tradingpartnercontactdetail`,
        tradingPartnerAccountNumber: () =>
          `${APIV1}/tradingpartner/delete/one/tradingpartneraccountnumber`,
      },
    },
  },
  user: {
    create: {
      one: () => `${APIV1}/user/create/one`,
    },
    read: {
      one: {
        by: {
          id: () => `${APIV1}/user/read/one/by/id`,
        },
      },
      all: {
        by: {
          customerId: () => `${APIV1}/user/read/all/by/customerid`,
        },
      },
      other: {
        by: {
          id: () => `${APIV1}/user/read/other/by/id`,
        },
      },
    },
    update: {
      toggleIsActive: () => `${APIV1}/user/update/toggleisactive`,
    },
  },
  userCustomerLocation: {
    create: {
      one: () => `${APIV1}/usercustomerlocation/create/one`,
    },
    read: {
      all: {
        by: {
          customerLocationId: () =>
            `${APIV1}/usercustomerlocation/read/all/by/customerlocationid`,
        },
      },
    },
    update: {
      one: {
        toggleAdmin: () =>
          `${APIV1}/usercustomerlocation/update/one/toggleadmin`,
      },
    },
    delete: {
      one: () => `${APIV1}/usercustomerlocation/delete/one`,
    },
  },
};

export default endpoints;
