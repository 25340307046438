import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { HydratedUser } from '../../../common/types/compoundModels.ts';
import { CustomerLocation } from '../../../common/types/models.ts';
import endpoints from '../../../common/endpoints.ts';
import api from '../../../common/api/index.ts';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  user?: HydratedUser;
  locationList: CustomerLocation[];
}

const GrantAccessModal = ({
  isOpen,
  setIsOpen,
  user,
  locationList,
}: IProps) => {
  const queryClient = useQueryClient();
  const modal = useRef<HTMLDivElement>(null);

  const initValues = () => ({
    userId: user?.id,
    customerLocationId: locationList[0]?.id || 0,
    isAdmin: false,
  });

  const [values, setValues] = useState(initValues());

  const initSelects = () =>
    M.FormSelect.init(document.querySelectorAll('select'));

  useLayoutEffect(() => {
    const { current } = modal;
    if (current) M.Modal.init(current, { onCloseEnd: () => setIsOpen(false) });
    initSelects();

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  useLayoutEffect(() => {
    initSelects();
    M.updateTextFields();
  }, [locationList]);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    setValues(initValues());
    if (modal) {
      if (isOpen) {
        modal.open();
      } else {
        modal.close();
      }
    }
  }, [isOpen]);

  const onChange = ({
    target: { id, value, type },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues(prev => ({
      ...prev,
      [id]: type === 'checkbox' ? !prev[id] : value,
    }));

  const cancel = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const { mutate: createUserCustomerLocation } = useMutation({
    mutationKey: [endpoints.userCustomerLocation.create.one(), values],
    mutationFn: () =>
      api.userCustomerLocation.create.one({
        ...values,
        userId: values.userId || 0,
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.all.by.customerId()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.other.by.id()],
      });
      if (data) cancel();
    },
  });

  const save = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    createUserCustomerLocation();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          {user && (
            <h5 className="center">
              Grant Access To {user.firstName} {user.lastName}
            </h5>
          )}
        </div>
        <div className="row">
          <div className="input-field col s12">
            <select
              id="customerLocationId"
              value={values.customerLocationId}
              onChange={onChange}
            >
              {locationList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
            <label htmlFor="customerLocationId">Location</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4 offset-m4">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={cancel}
            >
              cancel
            </a>
          </div>
          <div className="col s12 m4">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={save}
            >
              Grant Access
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrantAccessModal;
