import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import GrantAccessModal from './modals/GrantAccessModal.tsx';
import { CustomerLocation } from '../../common/types/models.ts';

interface IProps {
  canAddUser: boolean;
}

const UserDetail = ({ canAddUser }: IProps) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [fullName, setFullName] = useState<string>('');
  const [isOpenAccessModal, setIsOpenAccessModal] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<CustomerLocation[]>([]);

  const { data: currentUser } = useQuery({
    queryKey: [endpoints.user.read.one.by.id()],
    queryFn: api.user.read.one.by.id,
  });

  const { data: user } = useQuery({
    enabled: Boolean(id),
    queryKey: [endpoints.user.read.other.by.id(), id],
    queryFn: () => api.user.read.other.by.id(id || 0),
  });

  const { data: locations } = useQuery({
    queryKey: [endpoints.customerLocation.read.all.by.userId()],
    queryFn: api.customerLocation.read.all.by.userId,
  });

  const { mutate: deleteUserCustomerLocation } = useMutation({
    mutationKey: [endpoints.userCustomerLocation.delete.one()],
    mutationFn: (params: { userId: number; customerLocationId: number }) =>
      api.userCustomerLocation.delete.one(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.all.by.customerId()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.other.by.id()],
      });
    },
  });

  const { mutate: toggleUserCustomerLocationAdmin } = useMutation({
    mutationKey: [endpoints.userCustomerLocation.update.one.toggleAdmin()],
    mutationFn: (params: { userId: number; customerLocationId: number }) =>
      api.userCustomerLocation.update.one.toggleAdmin(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.all.by.customerId()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.other.by.id()],
      });
    },
  });

  const { mutate: updateUserToggleIsActive } = useMutation({
    mutationKey: [endpoints.user.update.toggleIsActive(), user],
    mutationFn: () => api.user.update.toggleIsActive(user?.id || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.all.by.customerId()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.user.read.other.by.id()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.customer.read.activeUserCount.by.customerId()],
      });
    },
  });

  useEffect(() => {
    if (locations && currentUser) {
      const allowedLocationIds = currentUser?.userCustomerLocations
        .filter(({ isAdmin }) => isAdmin)
        .map(({ customerLocationId }) => customerLocationId);

      const existingLocationIds = user?.userCustomerLocations?.map(
        ({ customerLocationId }) => customerLocationId
      );

      setLocationList(
        locations.filter(
          ({ id }) =>
            allowedLocationIds.includes(id) &&
            !existingLocationIds?.includes(id)
        )
      );
    } else setLocationList([]);
  }, [locations, user, currentUser]);

  const currentIsSuperAdmin: boolean = useMemo(() => {
    return Boolean(
      currentUser && currentUser.customer.adminId === currentUser.id
    );
  }, [currentUser]);

  const currentIsAdminOverAllListedLocations: boolean = useMemo(() => {
    if (currentUser?.userCustomerLocations && user?.userCustomerLocations) {
      const adminLocationsIds = currentUser.userCustomerLocations
        .filter(({ isAdmin }) => isAdmin)
        .map(({ customerLocationId }) => customerLocationId);

      const userLocationsIds = user.userCustomerLocations.map(
        ({ customerLocationId }) => customerLocationId
      );

      return userLocationsIds.every(id => adminLocationsIds.includes(id));
    } else return false;
  }, [currentUser, user]);

  useEffect(() => {
    if (user) {
      const { firstName, middleName, lastName } = user;
      const fullName = `${firstName} ${middleName || ''} ${lastName || ''}`;
      setFullName(fullName);
    }
  }, [user]);

  const openAddLocationModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setIsOpenAccessModal(true);
  };

  const showEditOptions = (isAdmin?: boolean) => {
    if (currentIsSuperAdmin) return true;
    if (!isAdmin) return true;
    return false;
  };

  const removeLocationAccess = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, customerLocationId: number) => {
      event?.preventDefault();
      deleteUserCustomerLocation({ userId: user?.id || 0, customerLocationId });
    },
    [user]
  );

  const toggleAdmin = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, customerLocationId: number) => {
      event?.preventDefault();
      if (
        currentIsSuperAdmin ||
        window.confirm(
          'Are you sure you want to make this user an admin? You will not be able to edit them after this change.'
        )
      )
        toggleUserCustomerLocationAdmin({
          userId: user?.id || 0,
          customerLocationId,
        });
    },
    [user]
  );

  const toggleUserIsActive = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (
      window.confirm(
        `Are you sure you want to ${
          user?.isActive ? 'de' : 're'
        }activate this user?`
      )
    )
      updateUserToggleIsActive();
  };

  return (
    <div>
      <div className="row">
        <h4>
          {fullName}{' '}
          {user && user?.id === currentUser?.id && (
            <span style={{ fontStyle: 'italic' }}>(ME)</span>
          )}{' '}
          {user && !user?.isActive ? (
            <span
              className="red-text text-lighten-1"
              style={{ fontStyle: 'italic' }}
            >
              - DEACTIVATED
            </span>
          ) : (
            ''
          )}
        </h4>
      </div>
      <div className="row">
        {locationList?.length ? (
          <div className="col s12 m4 offset-m8">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={openAddLocationModal}
            >
              Grant Location Access
            </a>
          </div>
        ) : null}
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Location Name</th>
              <th>Is Admin</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {user?.userCustomerLocations?.map(
              ({ customerLocationId, isAdmin, customerLocation: { name } }) => (
                <tr key={customerLocationId}>
                  <td style={{ padding: '4px' }}>{name}</td>
                  <td style={{ padding: '4px' }}>{isAdmin ? 'Yes' : 'No'}</td>
                  {showEditOptions(isAdmin) ? (
                    <td style={{ padding: '4px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <a
                          href="/"
                          onClick={event =>
                            toggleAdmin(event, customerLocationId)
                          }
                        >
                          {isAdmin ? 'Revoke Admin' : 'Make Admin'}
                        </a>
                        <a
                          href="/"
                          onClick={event =>
                            removeLocationAccess(event, customerLocationId)
                          }
                        >
                          Remove Access
                        </a>
                      </div>
                    </td>
                  ) : null}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {user && user.id !== currentUser?.id && (currentIsSuperAdmin || currentIsAdminOverAllListedLocations) ? (
        <div className="row">
          <div className="col s12 m4 offset-m8">
            {user?.isActive || canAddUser ? (
              <a
                href="/"
                className="btn-small red white-text waves-effect waves-light col s12"
                onClick={toggleUserIsActive}
              >
                {user?.isActive ? 'Deactivate' : 'Reactivate'} User
              </a>
            ) : null}
          </div>
        </div>
      ) : null}
      <GrantAccessModal
        isOpen={isOpenAccessModal}
        setIsOpen={setIsOpenAccessModal}
        user={user || undefined}
        locationList={locationList}
      />
    </div>
  );
};

export default UserDetail;
