import endpoints from '../endpoints.ts';
import api from '../api/index.ts';
import { RecallAPI } from '../api/recall.ts';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { Toast } from '../types/models';
import { RECALL_QUERY_KEY } from '../queryHooks/recallQueryHooks.ts';

const useCreateOneById = (
  options?: Partial<
    UseMutationOptions<
      Toast | null,
      unknown,
      RecallAPI.CreateOneDismissedRecall
    >
  >
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [endpoints.recall.create.one.dismissedRecall()],
    mutationFn: api.recall.create.one.dismissedRecall,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RECALL_QUERY_KEY],
      });
    },
    ...options,
  });
};

const useUpdateOneToggleDismissedRecall = (
  options: Partial<UseMutationOptions<Toast | null, unknown, number | string>>
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [endpoints.recall.update.one.toggleDismissedRecall()],
    mutationFn: api.recall.update.one.toggleDismissedRecall,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RECALL_QUERY_KEY],
      });
    },
    ...options,
  });
};

const recallMutationHooks = {
  useCreateOneById,
  useUpdateOneToggleDismissedRecall,
};

export default recallMutationHooks;
