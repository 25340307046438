import endpoints from '../endpoints.ts';
import { Customer } from '../types/models.ts';
import { fetch } from './helpers.ts';

const customer = {
  create: {},
  read: {
    one: {
      by: {
        userId: async (): Promise<Customer | null> => {
          const url = endpoints.customer.read.one.by.userId();
          return await fetch<Customer>(url, 'GET');
        },
      },
    },
    activeUserCount: {
      by: {
        customerId: async (): Promise<number | null> => {
          const url = endpoints.customer.read.activeUserCount.by.customerId();
          return await fetch<number>(url, 'GET');
        },
      },
    },
  },
  update: {},
  delete: {},
};

export default customer;
