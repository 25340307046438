import React, { useEffect, useState } from 'react';
import { useLocation, Link, matchPath } from 'react-router-dom';
import medTraxLogo from '../../assets/medtrax_logo.png';

const Banner = props => {
  const { pathname } = useLocation();
  const [page, setPage] = useState('Home');

  useEffect(() => {
    if (pathname === '/inputmeds') return setPage('Create A Pedigree');
    else if (pathname === '/searchmeds') return setPage('My Pedigrees');
    else if (matchPath('/meddetail/:medId', pathname))
      return setPage('Pedigree Detail');
    else if (pathname === '/sendapedigree') return setPage('Send A Pedigree');
    else if (matchPath('/sendapedigree/:serialNumber', pathname))
      return setPage(pathname.split('/sendapedigree/')[1]);
    else if (pathname === '/reportanincident')
      return setPage('Report An Incident');
    else if (pathname === '/incidenttracker')
      return setPage('Incident Tracker');
    else if (pathname === '/settings/changepassword')
      return setPage('Change Password');
    else if (pathname.includes('/usermanagement'))
      return setPage('User Management');
    else if (pathname.includes('/settings')) return setPage('Settings');
    else if (pathname === '/orders') return setPage('Search Overview');
    else if (matchPath('/orders/receive/:id', pathname))
      return setPage('Receive Order');
    else if (pathname === '/orderupload') return setPage('Order Upload');
    else if (pathname === '/demo/epcis/file/generator')
      return setPage('Demo EPCIS File Generator');
    else if (pathname.includes('/tradingpartner'))
      return setPage('Trading Partners');
    else if (pathname.includes('/gln'))
      return setPage('Global Location Numbers');
    else if (pathname.startsWith('/subscription'))
      return setPage('Manage Subscription');
    else if (pathname.startsWith('/locations'))
      return setPage('Manage Locations');
    else if (pathname.startsWith('/users')) return setPage('Manage Users');
    else if(pathname === '/recalls') return setPage('My Recalls')
    else if(pathname === '/recalls/search') return setPage('All Recalls');
    else if (pathname.startsWith('/recalls')) return setPage('Recalls');

    else return setPage('Welcome');
  }, [pathname]);

  return (
    <div
      id="hulk-banner"
      className="red"
      style={{ height: '128px', maxWidth: '100%', zIndex: -1 }}
    >
      <span className="center white-text">{page}</span>
      <div>
        <Link to="/">
          <img
            className="responsive-img right hide-on-med-and-down"
            src={medTraxLogo}
            alt="Outdate Rx Logo"
            style={{
              maxHeight: '90px',
              position: 'relative',
              bottom: '40px',
              right: '24px',
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default Banner;
