import React, {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
} from 'react';
import { HydratedRecallCode } from '../../../common/types/compoundModels';
import moment from 'moment';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  recallCode?: HydratedRecallCode;
}

const RecallCodeDetailModal = ({ isOpen, setIsOpen, recallCode }: IProps) => {
  const modal = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = modal;
    if (current)
      M.Modal.init(current, {
        onCloseEnd: () => setIsOpen(false),
      });

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    if (modal) isOpen ? modal.open() : modal.close();
  }, [isOpen]);

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5 className="center blue-text text-darken-4">{recallCode?.formFactor}</h5>
          <h6 className="center">NDC: {recallCode?.ndcWithDashes}</h6>
        </div>
        <div className="row">
          <table>
            <thead>
              <tr>
                <th>Expiration</th>
                <th>Lot Number(s)</th>
              </tr>
            </thead>
            <tbody>
              {recallCode?.criteria.map(crit => (
                <tr key={crit.id}>
                  <td style={{ padding: '4px' }}>
                    {moment(crit.expiration).format('MM/DD/YYYY')}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {crit.recallLotNumbers.map(rln => rln.lotNumber).join(', ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecallCodeDetailModal;
