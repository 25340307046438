import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import SubscriptionDetail from './SubscriptionDetail.tsx';
import ModifySubscription from './ModifySubscription.tsx';
import { useQuery } from '@tanstack/react-query';
import api from '../../common/api/index.ts';
import endpoints from '../../common/endpoints.ts';
import { FSSubscription } from '../../common/types/fastspringModels.ts';

const SubscriptionRouter = () => {
  const [refetchInterval, setRefetchInterval] = useState<number>(0);
  const [oldSubs, setOldSubs] = useState<FSSubscription[] | null>();
  const [clear, setClear] = useState<NodeJS.Timeout>();

  const { data: accounts } = useQuery({
    queryKey: [endpoints.fastspring.read.one.account.by.userId()],
    queryFn: api.fastspring.read.one.account.by.userId,
  });

  const { data: subscriptions } = useQuery({
    queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId()],
    queryFn: api.fastspring.read.all.subscriptions.by.userId,
    refetchInterval,
  });

  //In the event that the subscription changes, continue polling
  // for 1 minute after initial updates happen in case there
  // are unfetched side effects.
  useEffect(() => {
    if (JSON.stringify(oldSubs) !== JSON.stringify(subscriptions)) {
      setOldSubs(subscriptions);
      clearTimeout(clear);
      setTimeout(() => {
        setRefetchInterval(0);
      }, 60 * 1000);
    }
  }, [subscriptions, oldSubs]);

  //In the event they start to checkout but never purchase
  //their subscriptions will not change and the above useeffect
  //will never be satisfied. Poll for 10 mins then stop.
  useEffect(() => {
    if (refetchInterval) {
      clearTimeout(clear);
      const newClear = setTimeout(() => {
        setRefetchInterval(0);
      }, 10 * 60 * 1000);
      setClear(newClear);
    }
  }, [refetchInterval]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SubscriptionDetail
            accounts={accounts || []}
            subscriptions={subscriptions || []}
          />
        }
      />
      <Route
        path="/modify"
        element={
          <ModifySubscription
            accounts={accounts || []}
            subscriptions={subscriptions || []}
            setRefetchInterval={setRefetchInterval}
          />
        }
      />
    </Routes>
  );
};

export default SubscriptionRouter;
