import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
} from 'react';
import moment from 'moment';
import { HydratedEpcisSgtin } from '../../../common/types/compoundModels';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  sgtinList?: HydratedEpcisSgtin[];
  onDismissRecall: () => void;
  isDismissed: boolean;
}

const AffectedSgtinsModal = ({
  isOpen,
  setIsOpen,
  sgtinList,
  onDismissRecall,
  isDismissed,
}: IProps) => {
  const modal = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = modal;
    if (current)
      M.Modal.init(current, {
        onCloseEnd: () => setIsOpen(false),
      });

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    if (modal) isOpen ? modal.open() : modal.close();
  }, [isOpen]);

  const closeModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const dismissRecall = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
    onDismissRecall();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5 className="center">Affected Serial Numbers</h5>
          <table>
            <thead>
              <tr>
                <th>Serial #</th>
                <th>Received By</th>
                <th>Date Received</th>
              </tr>
            </thead>
            <tbody>
              {sgtinList?.map(({ id, receivedBy, datetimeReceived, sgtin }) => (
                <tr key={id}>
                  <td style={{ padding: '4px' }}>{sgtin.split('.').pop()}</td>
                  <td style={{ padding: '4px' }}>
                    {receivedBy?.firstName} {receivedBy?.lastName}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {datetimeReceived
                      ? moment(datetimeReceived).format('MM/DD/YY')
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col s12 m4 right">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={closeModal}
            >
              Close
            </a>
          </div>

          {!isDismissed ? (
            <div className="col s12 m4">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={dismissRecall}
              >
                Dismiss Recall
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AffectedSgtinsModal;
