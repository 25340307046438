import React, { useState } from 'react';
import { HydratedEpcisEvent } from '../../common/types/compoundModels';
import moment from 'moment';
import AffectedSgtinsModal from './modals/AffectedSgtinsModal.tsx';
import DismissRecallModal from './modals/DismissRecallModal.tsx';
import { useParams } from 'react-router-dom';

interface IProps {
  events?: HydratedEpcisEvent[];
}

const AffectedMedsTable = ({ events }: IProps) => {
  const { id } = useParams();

  const [isOpenSgtinModal, setIsOpenSgtinModal] = useState<boolean>(false);
  const [isOpenDismissedModal, setIsOpenDismissedModal] =
    useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<HydratedEpcisEvent>();

  const selectEvent = (event: HydratedEpcisEvent) => {
    setSelectedEvent(event);
    setIsOpenSgtinModal(true);
  };

  return (
    <div className="row" style={{ marginTop: '40px' }}>
      <h5>Your Affected Meds</h5>
      {events?.length ? (
        <table className="highlight">
          <thead>
            <tr>
              <th>Location</th>
              <th>Med Name</th>
              <th>Manufacturer</th>
              <th>NDC</th>
              <th>Expiration</th>
              <th>Lot #</th>
              <th>Date Shipment Sent</th>
            </tr>
          </thead>
          <tbody>
            {events.map(event => {
              const { epcisMedVocabulary } = event.epcisSgtins[0];
              return (
                <tr
                  key={event.id}
                  style={{
                    cursor: 'pointer',
                    fontStyle: event.dismissedRecall?.isDismissed
                      ? 'italic'
                      : '',
                  }}
                  onClick={() => selectEvent(event)}
                  className={
                    event.dismissedRecall?.isDismissed ? 'grey-text' : ''
                  }
                >
                  <td style={{ padding: '4px' }}>
                    {event.customerLocation.name}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {epcisMedVocabulary.regulatedProductName}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {epcisMedVocabulary.manufacturerOfTradeItemPartyName}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {epcisMedVocabulary.additionalTradeItemId}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {moment(event.expiration).format('MM/DD/YYYY')}
                  </td>
                  <td style={{ padding: '4px' }}>{event.lotNumber}</td>
                  <td style={{ padding: '4px' }}>
                    {moment(event.document.timestamp).format('MM/DD/YYYY')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>No Affected Meds Found</p>
      )}
      <AffectedSgtinsModal
        isOpen={isOpenSgtinModal}
        setIsOpen={setIsOpenSgtinModal}
        sgtinList={selectedEvent?.epcisSgtins}
        onDismissRecall={() => setIsOpenDismissedModal(true)}
        isDismissed={Boolean(selectedEvent?.dismissedRecall?.isDismissed)}
      />
      <DismissRecallModal
        isOpen={isOpenDismissedModal}
        setIsOpen={setIsOpenDismissedModal}
        onCancel={() => setIsOpenSgtinModal(true)}
        recallId={id || 0}
        epcisEventId={selectedEvent?.id || 0}
      />
    </div>
  );
};

export default AffectedMedsTable;
