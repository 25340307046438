import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ManageUsers from './ManageUsers.tsx';
import UserDetail from './UserDetail.tsx';

const ManageUsersRouter = () => {
  const [canAddUser, setCanAddUser] = useState<boolean>(false);
  
  return (
    <Routes>
      <Route path="/" element={<ManageUsers setCanAddUser={setCanAddUser} />} />
      <Route path="/:id" element={<UserDetail canAddUser={canAddUser} />} />
    </Routes>
  );
};

export default ManageUsersRouter;
