import styled from '@emotion/styled';
import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface IProps {
  triggerRef: MutableRefObject<HTMLElement | HTMLElement[]>;
  options: { [key: string]: () => any };
}

export const ContextMenu = ({ triggerRef, options }: IProps) => {
  const lastClicked = useRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [position, setPosition] = useState({ left: -1000, top: -1000 });
  const self = useRef<HTMLDivElement>(null);
  const minSelfWidth = useMemo(() => 129, []);

  const setCoords = (triggerElement?: HTMLElement) => {
    if (!triggerElement) return setPosition({ left: -1000, top: -1000 });

    const { innerWidth, innerHeight } = window;
    const { current: selfElement } = self;

    if (triggerElement && selfElement) {
      const {
        left: triggerLeft,
        bottom: triggerBottom,
        top: triggerTop,
        right: triggerRight,
      } = triggerElement.getBoundingClientRect();
      let { width: selfWidth, height: selfHeight } =
        selfElement.getBoundingClientRect();

      selfWidth = selfWidth || minSelfWidth;
      const combinedLeft = selfWidth + triggerLeft;
      const combinedTop = selfHeight + triggerBottom;

      const left =
        combinedLeft > innerWidth ? triggerRight - selfWidth : triggerLeft;
      const top =
        combinedTop > innerHeight ? triggerTop - selfHeight : triggerBottom;

      setPosition({ left, top });
    }
  };

  useLayoutEffect(() => {
    const { current } = triggerRef;
    const isArray = Array.isArray(current);

    const listener = (event: globalThis.MouseEvent) => {
      const target = event.target as HTMLElement;
      const next = target === lastClicked.current ? !isOpen : true;
      if (target) {
        setIsOpen(() => {
          setCoords(target as HTMLElement);
          return next;
        });
        lastClicked.current = target as HTMLElement;
      } else setIsOpen(false);
    };

    const clickAway = (event: globalThis.MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!self.current || !self.current.contains(target as Element))
        setIsOpen(false);
    };

    if (isArray)
      current.forEach(ref => ref.addEventListener('click', listener));
    else current?.addEventListener('click', listener);

    document.addEventListener('click', clickAway);
    return () => {
      document.removeEventListener('click', clickAway);

      if (isArray)
        current.forEach(el => el?.removeEventListener('click', listener));
      else current?.removeEventListener('click', listener);
    };
  }, [triggerRef.current, triggerRef.current?.[0], self.current]);

  const Div = useMemo(
    () => styled.div`
      min-width: 129px;
      border-radius: 10px;
      background: #eeebeb;
      z-index: 1000;
      position: fixed;
      top: ${position.top}px;
      left: ${position.left}px;
      display: ${isOpen ? 'flex' : 'none'};
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 8px 0px;
    `,
    [position, isOpen]
  );

  const Span = useMemo(
    () => styled.span`
      align-self: stretch;
      cursor: pointer;
      padding-left: 8px;

      &:hover {
        background: #d9d9d9;
      }
    `,
    []
  );

  const callAction = (action: () => any) => {
    setIsOpen(false);
    action();
  };

  return (
    <Div ref={self}>
      {Object.keys(options).map(key => (
        <Span key={key} style={{}} onClick={() => callAction(options[key])}>
          {key}
        </Span>
      ))}
    </Div>
  );
};

export default ContextMenu;
