import endpoints from '../endpoints.ts';
import { Toast } from '../types/models.ts';
import { fetch } from './helpers.ts';
import { HydratedUser } from '../types/compoundModels.ts';

const user = {
  create: {
    one: async (body: {
      customerId: number;
      firstName: string;
      lastName: string;
      emailAddress: string;
      title?: string;
    }): Promise<Toast | null> => {
      const url = endpoints.user.create.one();
      return await fetch<Toast>(url, 'POST', body);
    },
  },
  read: {
    one: {
      by: {
        id: async (): Promise<HydratedUser | null> => {
          const url = endpoints.user.read.one.by.id();
          return await fetch<HydratedUser>(url, 'GET');
        },
      },
    },
    all: {
      by: {
        customerId: async (): Promise<HydratedUser[] | null> => {
          const url = endpoints.user.read.all.by.customerId();
          return await fetch<HydratedUser[]>(url, 'GET');
        },
      },
    },
    other: {
      by: {
        id: async (userId: number | string): Promise<HydratedUser | null> => {
          const url = endpoints.user.read.other.by.id();
          return await fetch<HydratedUser>(url, 'GET', { userId });
        },
      },
    },
  },
  update: {
    toggleIsActive: async (userId: number | string): Promise<Toast | null> => {
      const url = endpoints.user.update.toggleIsActive();
      return await fetch<Toast>(url, 'PUT', { userId });
    },
  },
  delete: {},
};

export default user;
