import React, { useEffect, useState } from 'react';
import queryHooks from '../../common/queryHooks/index.ts';
import { Link, useNavigate } from 'react-router-dom';
import { RecallAPI } from '../../common/api/recall.ts';
import moment from 'moment';

interface IProps {
  isNracSubscribed: boolean;
}

const ActiveRecallList = ({ isNracSubscribed }: IProps) => {
  const navigate = useNavigate();

  const { data: activeRecalledMedCount } =
    queryHooks.recall.useReadOneActiveRecallCountByUserId();

  const [recallCodeIdList, setRecallCodeIdList] = useState<number[]>([]);
  const [medCountsByRecallId, setMedCountsByRecallId] = useState<{
    [key: number]: number;
  }>();

  const { data: recalls } = queryHooks.recall.useReadAllByRecallCodeIds(
    recallCodeIdList,
    {
      enabled: Boolean(recallCodeIdList?.length),
    }
  );

  const { data: activeRecallList } =
    queryHooks.recall.useReadAllActiveRecallsByRecallCodeIds(recallCodeIdList, {
      enabled: Boolean(recallCodeIdList.length),
    });

  const parseRecallCodeIds = (
    activeRecalledMedCount: RecallAPI.ReadOneActiveRecallMedCountReturn
  ) => {
    const recallCodeIdList = activeRecalledMedCount.map(
      ({ recallCodeId }) => recallCodeId
    );
    return recallCodeIdList;
  };

  const parseMedCountsByRecallId = (
    activeRecalledMedCount: RecallAPI.ReadOneActiveRecallMedCountReturn
  ) => {
    const obj = {};
    activeRecalledMedCount.forEach(
      ({ recallCodeId, affectedMedCount }) =>
        (obj[recallCodeId] = affectedMedCount)
    );
    return obj;
  };

  useEffect(() => {
    if (activeRecalledMedCount) {
      const recallCodeIdList = parseRecallCodeIds(activeRecalledMedCount);
      setRecallCodeIdList(recallCodeIdList);
      const medCountsByRecallId = parseMedCountsByRecallId(
        activeRecalledMedCount
      );
      setMedCountsByRecallId(medCountsByRecallId);
    }
  }, [activeRecalledMedCount]);

  const navigateToRecallDetail = (recallId: number) =>
    navigate(`/recalls/detail/${recallId}`);

  if (!isNracSubscribed) return null;
  return (
    <div>
      <div className="row" style={{ marginTop: '18px' }}>
        <Link className="right" to="/recalls/search">
          Search All Recalls
        </Link>
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Location</th>
              <th>Med Name</th>
              <th>Manufacturer</th>
              <th>Recall Date</th>
              <th>NDCs</th>
              <th style={{ textAlign: 'right' }}># of Affected Items</th>
            </tr>
          </thead>
          <tbody>
            {recalls?.map(
              ({
                id,
                manufacturer,
                product,
                recallCodes,
                recallDocument: { dateReceived },
              }) => (
                <tr
                  key={id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigateToRecallDetail(id)}
                >
                  <td style={{ padding: '4px' }}>
                    {
                      activeRecallList?.find(({ recallCodeId }) =>
                        recallCodes.map(({ id }) => id).includes(recallCodeId)
                      )?.customerLocation.name
                    }
                  </td>
                  <td style={{ padding: '4px' }}>{product}</td>
                  <td style={{ padding: '4px' }}>{manufacturer}</td>
                  <td style={{ padding: '4px' }}>
                    {moment(dateReceived).format('MM/DD/YY')}
                  </td>
                  <td style={{ padding: '4px' }}>
                    {recallCodes
                      .map(({ ndcWithDashes }) => ndcWithDashes)
                      .join(', ')}
                  </td>
                  <td style={{ padding: '4px', textAlign: 'right' }}>
                    {recallCodes.reduce(
                      (acc, { id }) => acc + (medCountsByRecallId?.[id] || 0),
                      0
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveRecallList;
