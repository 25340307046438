import endpoints from '../endpoints.ts';
import api from '../api/index.ts';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HydratedUser } from '../types/compoundModels.ts';

const useReadOneById = (
  options?: Partial<UseQueryOptions<HydratedUser | null>>
) =>
  useQuery({
    queryKey: [endpoints.user.read.one.by.id()],
    queryFn: api.user.read.one.by.id,
    ...options,
  });

const userQueryHooks = {
  useReadOneById,
};

export default userQueryHooks;
