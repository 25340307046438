import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DismissedRecall } from '../../../common/types/models';
import mutationHooks from '../../../common/mutationHooks/index.ts';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  recallId: number | string;
  epcisEventId: number | string;
  dismissedRecall?: DismissedRecall;
  onCancel: () => void;
}

const DismissRecallModal = ({
  isOpen,
  setIsOpen,
  recallId,
  epcisEventId,
  dismissedRecall,
  onCancel,
}: IProps) => {
  const modal = useRef<HTMLDivElement>(null);

  const [notes, setNotes] = useState<string>('');
  const [lastCallIsSuccess, setLastCallIsSuccess] = useState(false);

  const { mutate: createDismissedRecall } =
    mutationHooks.recall.useCreateOneById();

  useLayoutEffect(() => {
    const { current } = modal;
    if (current)
      M.Modal.init(current, {
        dismissible: false,
        onCloseEnd: () => {
          setIsOpen(false);
        },
        onOpenStart: () => {
          setNotes('');
        },
      });

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    if (modal) isOpen ? modal.open() : modal.close();
  }, [isOpen]);

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
    onCancel();
  };

  const dismissRecall = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    createDismissedRecall({
      recallId,
      epcisEventId,
      notes,
    });
    getModal()?.close();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5 className="center">Dismiss Recall</h5>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <textarea
              id="notes"
              className="materialize-textarea text-area-blue"
              value={notes}
              onChange={({ target: { value } }) => setNotes(value)}
            />
            <label htmlFor="notes">Notes</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={cancel}
            >
              cancel
            </a>
          </div>
          <div className="col s12 m4 offset-m4">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={dismissRecall}
            >
              Save & Dismiss
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DismissRecallModal;
