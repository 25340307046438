import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Recalls from './Recalls.tsx';
import RecallDetail from './RecallDetail.tsx';
import { FastspringSubscriptionName } from '../../common/enums.ts';
import queryHooks from '../../common/queryHooks/index.ts';
import RecallSearch from './RecallSearch.tsx';

const RecallRouter = () => {
  const [isNracSubscribed, setIsNracSubscribed] = useState<boolean>(false);
  const nracSubcriptionNames = useMemo(
    () =>
      Object.values(FastspringSubscriptionName)
        .filter(name => name.includes('nrac'))
        .map(name => name.toString()),
    []
  );

  const { data: subscriptions, isFetching } =
    queryHooks.fastspring.useReadAllSubscriptionsById();

  useEffect(() => {
    if (subscriptions)
      setIsNracSubscribed(
        subscriptions.some(
          sub => nracSubcriptionNames.includes(sub.product) && sub.active
        )
      );
    else setIsNracSubscribed(false);
  }, [subscriptions, nracSubcriptionNames]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Recalls
            isNracSubscribed={isNracSubscribed}
            isFetching={isFetching}
          />
        }
      />
      <Route
        path="/search"
        element={<RecallSearch isNracSubscribed={isNracSubscribed} />}
      />
      <Route
        path="/detail/:id"
        element={<RecallDetail isNracSubscribed={isNracSubscribed} />}
      />
    </Routes>
  );
};

export default RecallRouter;
