import React, { MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import queryHooks from '../../common/queryHooks/index.ts';
import { HydratedRecallCode } from '../../common/types/compoundModels.ts';
import RecallCodeDetailModal from './modals/RecallCodeDetailModal.tsx';
import AffectedMedsTable from './AffectedMedsTable.tsx';

interface IProps {
  isNracSubscribed: boolean;
}

const RecallDetail = ({ isNracSubscribed }: IProps) => {
  const { id } = useParams();

  const [currentRecallCode, setCurrentRecallCode] =
    useState<HydratedRecallCode>();
  const [isOpenRecallCodeModal, setIsOpenRecallCodeModal] =
    useState<boolean>(false);

  const { data: recall } = queryHooks.recall.useReadOneById(id || 0, {
    enabled: Boolean(id),
  });

  const { data: events } = queryHooks.recall.useReadAllActiveRecallsByRecallId(
    id || 0,
    {
      enabled: Boolean(id),
    }
  );

  const openCodeModal = (
    event: MouseEvent<HTMLAnchorElement>,
    recallCode: HydratedRecallCode
  ) => {
    event?.preventDefault();
    setCurrentRecallCode(recallCode);
    setIsOpenRecallCodeModal(true);
  };

  if (!isNracSubscribed) return null;
  return (
    <div>
      <div className="row">
        <h5>Recall Details</h5>
        <table>
          <tbody>
            <tr>
              <td style={{ padding: '4px' }}>Product</td>
              <td style={{ padding: '4px' }}>{recall?.product}</td>
            </tr>
            <tr>
              <td style={{ padding: '4px' }}>Manufacturer</td>
              <td style={{ padding: '4px' }}>{recall?.manufacturer}</td>
            </tr>
            <tr>
              <td style={{ padding: '4px' }}>
                Recall Number
                {recall && recall?.recallNumbers.length > 1 ? 's' : ''}
              </td>
              <td style={{ padding: '4px' }}>
                {recall?.recallNumbers.map(rln => rln.recallNumber).join(', ')}
              </td>
            </tr>
            <tr>
              <td style={{ padding: '4px' }}>Distribution</td>
              <td style={{ padding: '4px' }}>
                {recall?.recallDistributions
                  .map(rd => rd.distribution)
                  .join(', ')}
              </td>
            </tr>
            <tr>
              <td style={{ padding: '4px' }}>Codes</td>
              <td style={{ padding: '4px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}
                >
                  {recall?.recallCodes.map(code => (
                    <a
                      key={code.id}
                      href="/"
                      onClick={event => openCodeModal(event, code)}
                    >
                      {code.formFactor} {code.ndcWithDashes}
                    </a>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '4px' }}>Quantity</td>
              <td style={{ padding: '4px' }}>{recall?.quantity}</td>
            </tr>
            <tr>
              <td style={{ padding: '4px' }}>Reason</td>
              <td style={{ padding: '4px' }}>{recall?.reason}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <AffectedMedsTable events={events || undefined} />
      <RecallCodeDetailModal
        isOpen={isOpenRecallCodeModal}
        setIsOpen={setIsOpenRecallCodeModal}
        recallCode={currentRecallCode}
      />
    </div>
  );
};

export default RecallDetail;
