import endpoints from '../endpoints.ts';
import api from '../api/index.ts';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HydratedEpcisEvent, HydratedRecall } from '../types/compoundModels.ts';
import { RecallAPI } from '../api/recall.ts';

export const RECALL_QUERY_KEY = 'recall_query_key';

const useReadOneById = (
  recallId: number | string,
  options?: Partial<UseQueryOptions<HydratedRecall | null>>
) =>
  useQuery({
    queryKey: [RECALL_QUERY_KEY, endpoints.recall.read.one.by.id(), recallId],
    queryFn: () => api.recall.read.one.by.id(recallId),
    ...options,
  });

const useReadOneActiveRecallCountByUserId = (
  options?: Partial<
    UseQueryOptions<RecallAPI.ReadOneActiveRecallMedCountReturn | null>
  >
) =>
  useQuery({
    queryKey: [
      RECALL_QUERY_KEY,
      endpoints.recall.read.one.activeRecalledMedCount.by.userId(),
    ],
    queryFn: api.recall.read.one.activeRecalledMedCount.by.userId,
    ...options,
  });

const useReadManySearch = (
  filters: RecallAPI.ReadManySearch,
  options?: Partial<UseQueryOptions<HydratedRecall[] | null>>
) =>
  useQuery({
    queryKey: [RECALL_QUERY_KEY, endpoints.recall.read.many.search(), filters],
    queryFn: () => api.recall.read.many.search(filters),
    ...options,
  });

const useReadManySearchCount = (
  filters: RecallAPI.ReadManySearchCount,
  options?: Partial<UseQueryOptions<{ totalRows: number } | null>>
) =>
  useQuery({
    queryKey: [
      RECALL_QUERY_KEY,
      endpoints.recall.read.many.searchCount(),
      filters,
    ],
    queryFn: () => api.recall.read.many.searchCount(filters),
    ...options,
  });

const useReadAllByRecallCodeIds = (
  recallCodeIds: number[] | string[],
  options?: Partial<UseQueryOptions<HydratedRecall[] | null>>
) =>
  useQuery({
    queryKey: [
      RECALL_QUERY_KEY,
      endpoints.recall.read.all.by.recallCodeIds(),
      recallCodeIds,
    ],
    queryFn: () => api.recall.read.all.by.recallCodeIds(recallCodeIds),
    ...options,
  });

const useReadAllActiveRecallsByRecallId = (
  recallId: string | number,
  options?: Partial<UseQueryOptions<HydratedEpcisEvent[] | null>>
) =>
  useQuery({
    queryKey: [
      RECALL_QUERY_KEY,
      endpoints.recall.read.all.activeRecalls.by.recallId(),
      recallId,
    ],
    queryFn: () => api.recall.read.all.activeRecalls.by.recallId(recallId),
    ...options,
  });

const useReadAllActiveRecallsByRecallCodeIds = (
  recallCodeIds: number[] | string[],
  options?: Partial<UseQueryOptions<HydratedEpcisEvent[] | null>>
) =>
  useQuery({
    queryKey: [
      RECALL_QUERY_KEY,
      endpoints.recall.read.all.activeRecalls.by.recallCodeIds(),
      recallCodeIds,
    ],
    queryFn: () =>
      api.recall.read.all.activeRecalls.by.recallCodeIds(recallCodeIds),
    ...options,
  });

const recallQueryHooks = {
  useReadOneById,
  useReadOneActiveRecallCountByUserId,
  useReadManySearch,
  useReadManySearchCount,
  useReadAllByRecallCodeIds,
  useReadAllActiveRecallsByRecallId,
  useReadAllActiveRecallsByRecallCodeIds,
};

export default recallQueryHooks;
