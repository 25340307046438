import { GeneralSalesItems, MMCAPSalesItems } from "./enums.ts"

export const fsProductDetails: {
    [key: string]: {displayName: string, description: string, imgPath: string}
  } = {
    [MMCAPSalesItems.medtraxMMCAPService]: {
      displayName: 'Medtrax Service',
      description:
        'MedTrax Service includes one user account and all of the tools you need for compliance.',
      imgPath: '/images/medtrax_logo.png',
    },
    [MMCAPSalesItems.additionalUserMMCAP]: {
      displayName: 'Additional User Account',
      description:
        'Add additional users now or call later to add more authorized users to your account.',
      imgPath: '/images/additional_user.jpg',
    },
    [MMCAPSalesItems.dscsaSOPCreation]: {
      displayName: 'Creation of SOPs',
      description:
        'A thorough SOP for DSCSA compliance.  Covering everything from Licensing to Verification of Trading Partners, our DSCSA SOP will get your pharmacy on track for DSCSA compliance.',
      imgPath: '/images/SOPs.jpg',
    },
    [MMCAPSalesItems.trainingSession1Hour]: {
      displayName: 'Training Session',
      description:
        'Web training session with our MedTrax team.  Our staff will go over account setup, implementation, day to day use and system features and ensure you feel comfortable with all aspects of the DSCSA',
      imgPath: '/images/training_session.jpg',
    },
    [MMCAPSalesItems.nracAlerts]: {
      displayName: 'National Recall Alert Center Subscription',
      description:
        'Get notified of all recalls in your pharmacy.  NRAC has not missed a recall in their nearly 50 years in business.  Protect yourself and your patients with this incredible offer.',
      imgPath: '/images/NRAC.jpg',
    },
    [GeneralSalesItems.medtraxBasicService]: {
      displayName: 'MedTrax Basic',
      description: "Our Basic plan is great for all pharmacies that want to save time and money.  With 25 GB of storage space, you'll be able to store data for approximately 500 medications per day.  With full EDI data link, your purchases will automatically appear in the system and allow you to receive data automatically saving you time.  ",
      imgPath: '/images/pharmacy_red.jpg'   
    },
    [GeneralSalesItems.medtraxEnterpriseService]: {
      displayName: 'MedTrax Unlimited',
      description: "Our Unlimited Plan offers complete flexibility. With unlimited storage, you can securely store all your data without restrictions. Additionally, unlimited user access allows multiple team members to manage orders seamlessly.",
      imgPath: '/images/hospital_red.jpg'   
    },
    [GeneralSalesItems.additionalUserMonthly]: {
      displayName: 'Additional User Account',
      description: "Add additional users now or call later to add more authorized users to your account.",
      imgPath: '/images/additional_user.jpg'    
    },
    [GeneralSalesItems.dscsaSOPs]: {
      displayName: 'DSCSA SOP Creation',
      description: "A thorough SOP for DSCSA compliance.  Covering everything from Licensing to Verification of Trading Partners, our DSCSA SOP will get your pharmacy on track for DSCSA compliance.",
      imgPath: '/images/SOPs.jpg'   
    },
    [GeneralSalesItems.medtraxTrainingSession]: {
      displayName: '1 Hour Training Session',
      description: "1 hour virtual training session/ webinar with a member of the MedTrax customer service team.  We will cover topics such as: DSCSA Compliance, Registering Trading Partners for Authorization, Receiving Data files, Verifying Data and Searching for Product Files.",
      imgPath: '/images/training_session.jpg'   
    },
    [GeneralSalesItems.nracMonthlySubscription]: {
      displayName: 'Recall Alert Subscription',
      description: "Get custom notifications of all recalls in your pharmacy.  Powered by NRAC’s leading system, it has not missed a recall in their 50+ years in business.  Protect yourself and your patients with this incredible offer.",
      imgPath: '/images/NRAC.jpg'   
    },
    'enterprise-upgrade': {
      displayName: 'Upgrade to Unlimited',
      description: "Our Unlimited Plan offers complete flexibility. With unlimited storage, you can securely store all your data without restrictions. Additionally, unlimited user access allows multiple team members to manage orders seamlessly.",
      imgPath: '/images/hospital_red.jpg',
    }
  }