import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  FSAccount,
  FSDiscountENUM,
  FSIntervalENUM,
  FSItem,
  FSProduct,
  FSRenewENUM,
  FSSubscription,
} from '../../common/types/fastspringModels.ts';
import { useQuery } from '@tanstack/react-query';
import api from '../../common/api/index.ts';
import endpoints from '../../common/endpoints.ts';
import M from 'materialize-css';

import { CiShoppingCart } from 'react-icons/ci';
import { fsProductDetails } from '../../common/fastspringConstants.ts';
import {
  MMCAPSalesItemsAsArray,
  GeneralSalesItemsAsArray,
  FastspringSubscriptionName,
  FastspringUnlimitedUserItemsArray,
  GeneralSalesItems,
  FSSubscriptionState,
} from '../../common/enums.ts';
import fastspring, { FastspringAPI } from '../../common/api/fastspring.ts';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';
import moment from 'moment';

const medtraxFSUrl =
  process.env.REACT_APP_FEATURE_ENV === 'production'
    ? 'https://medtrax.onfastspring.com/popup-defaultB2B/session'
    : 'https://medtrax.test.onfastspring.com/popup-defaultB2B/session';

interface IProps {
  accounts: FSAccount[];
  subscriptions: FSSubscription[];
  setRefetchInterval: Dispatch<SetStateAction<number>>;
}

interface IProductDetail {
  displayName: string;
  description: string;
  imgPath: string;
  fsProduct: FSProduct;
}

interface ICartItem {
  productDetail: IProductDetail;
  quantity: number;
}

const ModifySubscription = ({
  accounts,
  subscriptions,
  setRefetchInterval,
}: IProps) => {
  const rightNow = useMemo(() => Date.now(), []);
  const cartModal = useRef<HTMLDivElement>(null);
  const addModal = useRef<HTMLDivElement>(null);
  const addModalEnterpriseUpgrade = useRef<HTMLDivElement>(null);
  const [cart, setCart] = useState<ICartItem[]>([]);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [hoverRow, setHoverRow] = useState<number>(-1);
  const [hydratedProductDetails, setHydratedProductDetails] = useState<{
    [key: string]: IProductDetail;
  }>({});
  const [isMmcap, setIsMmcap] = useState<boolean>(false);
  const [isUnlimited, setIsUnlimited] = useState<boolean>(false);
  const [mmcapProducts, setMmcapProducts] = useState<FSProduct[]>([]);
  const [nonMmcapProducts, setNonMmcapProducts] = useState<FSProduct[]>([]);
  const [currentlyViewedProduct, setCurrentlyViewedProduct] =
    useState<IProductDetail>();

  const [proratedCost, setProratedCost] = useState<number>(0);
  const [discounts, setDiscounts] = useState<number[]>([]);
  const [numberOfBasicSubs, setNumberOfbasicSubs] = useState<number>(0);
  const [priceOfBasic, setPriceOfBasic] = useState<number>(0);
  const [priceOfEnterprise, setPriceOfEnterprise] = useState<number>(0);

  const daysUntil = useCallback(
    (targetDate: number): number => {
      const today = moment(rightNow); // Current date
      const future = moment(targetDate); // Target future date
      return future.diff(today, 'days'); // Difference in days
    },
    [rightNow]
  );

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const { data: products } = useQuery({
    queryKey: [endpoints.fastspring.read.all.products()],
    queryFn: api.fastspring.read.all.products,
  });

  const hydrateProductDetails = useCallback(() => {
    const tempHydratedProductDetails: { [key: string]: IProductDetail } = {};

    for (const productKey in fsProductDetails) {
      const found = products?.filter(
        product => product.product === productKey
      )[0];

      let detailsKey: string | undefined = undefined;
      if (
        isBasicSubscription(isMmcap, isUnlimited) &&
        productKey === GeneralSalesItems.medtraxEnterpriseService
      ) {
        detailsKey = 'enterprise-upgrade';
      } else {
        detailsKey = productKey;
      }

      if (found)
        tempHydratedProductDetails[productKey] = {
          displayName: fsProductDetails[detailsKey].displayName,
          description: fsProductDetails[detailsKey].description,
          imgPath: fsProductDetails[detailsKey].imgPath,
          fsProduct: {
            product: productKey,
            pricing: {
              US: {
                currency: found?.pricing.US.currency,
                price: found?.pricing.US.price,
                display: found?.pricing.US.display,
              },
            },
          },
        };
    }

    setHydratedProductDetails(tempHydratedProductDetails);
  }, [setHydratedProductDetails, products, isMmcap, isUnlimited]);

  useLayoutEffect(() => {
    if (cartModal.current)
      M.Modal.init(cartModal.current, {
        onOpenStart: () => {
          getAddModal()?.close();
          getAddModalEnterpriseUpgrade()?.close();
        },
      });

    if (addModal.current)
      M.Modal.init(addModal.current, {
        onCloseEnd: () => {
          setProductQuantity(1);
        },
      });

    if (addModalEnterpriseUpgrade.current)
      M.Modal.init(addModalEnterpriseUpgrade.current, {
        onCloseEnd: () => {
          setProductQuantity(1);
        },
      });

    const { current: currentCart } = cartModal;
    const { current: currentAdd } = addModal;
    const { current: currentAddEnterpriseUpgrade } = addModalEnterpriseUpgrade;

    return () => {
      if (currentCart) M.Modal.getInstance(currentCart)?.destroy();
      if (currentAdd) M.Modal.getInstance(currentAdd)?.destroy();
      if (currentAddEnterpriseUpgrade)
        M.Modal.getInstance(currentAddEnterpriseUpgrade)?.destroy();
    };
  }, []);

  useEffect(() => {
    let newDiscounts: number[] = [];
    for (let sub of subscriptions) {
      let product = sub.product;
      if (
        product === FastspringSubscriptionName.medtraxBasicService ||
        product === FastspringSubscriptionName.medtraxPlusService
      ) {
        if (sub.state.toLocaleLowerCase() === FSSubscriptionState.active) {
          const daysLeftInSub = daysUntil(sub.nextChargeDate);
          const numDaysInMonth = moment().daysInMonth();
          const costPerDay = sub.subtotal / numDaysInMonth;
          const subDiscount = costPerDay * daysLeftInSub;
          newDiscounts.push(subDiscount);
        }
      }
    }
    return setDiscounts(newDiscounts);
  }, [subscriptions, rightNow, daysUntil]);

  useEffect(() => {
    setNumberOfbasicSubs(
      subscriptions
        .filter(sub => {
          return (
            (sub.product === FastspringSubscriptionName.medtraxBasicService ||
              sub.product === FastspringSubscriptionName.medtraxPlusService) &&
            sub.state.toLocaleLowerCase() === FSSubscriptionState.active
          );
        })
        .reduce((acc, sub) => acc + sub.quantity, 0)
    );

    setIsMmcap(
      subscriptions.some(
        subscription =>
          subscription.product
            .toLocaleLowerCase()
            .includes(FastspringSubscriptionName.medtraxMMCAPService) &&
          subscription.state.toLocaleLowerCase() === FSSubscriptionState.active
      )
    );
    setIsUnlimited(
      subscriptions.some(
        subscription =>
          FastspringUnlimitedUserItemsArray.includes(
            subscription.product.toLocaleLowerCase()
          ) &&
          subscription.state.toLocaleLowerCase() === FSSubscriptionState.active
      )
    );
    setProratedCost(
      numberOfBasicSubs * priceOfEnterprise -
        discounts.reduce((acc, num) => acc + num, 0)
    );
  }, [
    subscriptions,
    numberOfBasicSubs,
    priceOfEnterprise,
    discounts,
    rightNow,
  ]);

  useEffect(() => {
    const getPriceOfBasic = () => {
      const medtraxbasic = products?.find(
        product =>
          product.product === FastspringSubscriptionName.medtraxBasicService
      );
      if (medtraxbasic && medtraxbasic.pricing.US.price) {
        return medtraxbasic.pricing.US?.price;
      } else {
        throw new Error('Could not get price of Medtrax Basic');
      }
    };

    const getPriceOfEnterprise = () => {
      const medtraxEnterprise = products?.find(
        product =>
          product.product ===
          FastspringSubscriptionName.medtraxEnterpriseService
      );
      if (medtraxEnterprise && medtraxEnterprise.pricing.US.price) {
        return medtraxEnterprise.pricing.US?.price;
      } else {
        throw new Error('Could not get price of Medtrax Enterprise');
      }
    };

    if (products) {
      setMmcapProducts(
        products.filter(({ product }) =>
          MMCAPSalesItemsAsArray.includes(product)
        )
      );
      setNonMmcapProducts(
        products.filter(({ product }) =>
          GeneralSalesItemsAsArray.includes(product)
        )
      );
      setPriceOfBasic(getPriceOfBasic());
      setPriceOfEnterprise(getPriceOfEnterprise());
      hydrateProductDetails();
    }
  }, [products, hydrateProductDetails, priceOfBasic, priceOfEnterprise]);

  const getCartModal = () =>
    cartModal.current ? M.Modal.getInstance(cartModal.current) : null;

  const getAddModal = () =>
    addModal.current ? M.Modal.getInstance(addModal.current) : null;

  const getAddModalEnterpriseUpgrade = () =>
    addModalEnterpriseUpgrade.current
      ? M.Modal.getInstance(addModalEnterpriseUpgrade.current)
      : null;

  const incrementQuantity = () => setProductQuantity(productQuantity + 1);
  const decrementQuantity = () => {
    const newValue = productQuantity - 1;

    if (newValue > 0) setProductQuantity(newValue);
    else setProductQuantity(1);
  };

  const previewProduct = (
    event: MouseEvent<HTMLAnchorElement>,
    productName: string
  ) => {
    event?.preventDefault();

    if (
      isBasicSubscription(isMmcap, isUnlimited) &&
      productName === GeneralSalesItems.medtraxEnterpriseService
    ) {
      getAddModalEnterpriseUpgrade()?.open();
    } else {
      getAddModal()?.open();
    }

    setCurrentlyViewedProduct(hydratedProductDetails[productName]);
  };

  const isBasicSubscription = (
    isMmcap: boolean,
    isUnlimited: boolean
  ): boolean => {
    return !isMmcap && !isUnlimited;
  };

  const checkoutCart = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    getCartModal()?.close();

    if (!cart || cart.length === 0) return;

    const accountNumber = accounts[0]?.account;
    const items: FSItem[] = [];
    // if basic and upgrade is in the cart
    if (
      isBasicSubscription(isMmcap, isUnlimited) &&
      cart.some(
        ({
          productDetail: {
            fsProduct: { product },
          },
        }) => product === GeneralSalesItems.medtraxEnterpriseService
      )
    ) {
      for (let cartItem of cart) {
        if (cartItem.productDetail.fsProduct.product === 'prorated-discount') {
          continue;
        }
        if (
          cartItem.productDetail.fsProduct.product ===
          GeneralSalesItems.medtraxEnterpriseService
        ) {
          const itemDiscountPercent =
            ((priceOfEnterprise * cartItem.quantity - proratedCost) /
              cartItem.quantity /
              priceOfEnterprise) *
            100;
          const roundedPercent = Math.floor(itemDiscountPercent * 100) / 100;

          items.push({
            product: cartItem.productDetail.fsProduct.product,
            quantity: cartItem.quantity,
            pricing: {
              price: {
                USD: priceOfEnterprise,
              },
              renew: FSRenewENUM.auto,
              interval: FSIntervalENUM.month,
              intervalLength: 1,
              discountType: FSDiscountENUM.percent, // using percent because "amount" doesn't work
              discountDuration: 1,
              quantityDiscounts: {
                '1': roundedPercent,
              },
            },
          });
        } else {
          items.push({
            product: cartItem.productDetail.fsProduct.product,
            quantity: cartItem.quantity,
          });
        }
      }
    } else {
      for (let cartItem of cart) {
        items.push({
          product: cartItem.productDetail.fsProduct.product,
          quantity: cartItem.quantity,
        });
      }
    }

    const fsSession: FastspringAPI.FSSessionResponse | null =
      await fastspring.create.cart({
        account: accountNumber,
        items,
      });

    if (fsSession?.id !== null) {
      setRefetchInterval(5 * 1000);
      const redirectUrl = `${medtraxFSUrl}/${fsSession?.id}`;
      window.open(redirectUrl, '_blank');
      // clear cart
      setCart([]);
    } else console.error('FS session response is null');
  };

  const goToCart = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getCartModal()?.open();
  };

  const getCartQuantity = () =>
    cart.reduce((sum, item) => sum + item?.quantity || 0, 0);

  const getCartTotal = (): number => {
    let cartTotal = 0;
    for (const item of cart) {
      if (item.productDetail.fsProduct.pricing.US.price && item.quantity > 0) {
        if (item.productDetail.fsProduct.product === 'prorated-discount') {
          continue;
        }
        if (
          item.productDetail.fsProduct.product ===
          GeneralSalesItems.medtraxEnterpriseService
        ) {
          cartTotal += proratedCost;
        } else {
          cartTotal +=
            item.productDetail.fsProduct.pricing.US?.price * item.quantity;
        }
      }
    }

    return cartTotal;
  };

  const addToCart = (
    event: MouseEvent<HTMLAnchorElement>,
    productName: string,
    quantity: number
  ) => {
    event?.preventDefault();
    getCartModal()?.open();

    if (isBasicSubscription(isMmcap, isUnlimited)) {
      // if cart containd an enterprise sub don't allow basic or additional user
      if (
        cart.some(
          item =>
            item.productDetail.fsProduct.product ===
            GeneralSalesItems.medtraxEnterpriseService
        )
      ) {
        if (
          productName === GeneralSalesItems.medtraxBasicService ||
          productName === GeneralSalesItems.additionalUserMonthly ||
          productName === GeneralSalesItems.medtraxEnterpriseService
        ) {
          return;
        }
      }
      if (
        cart.some(
          item =>
            item.productDetail.fsProduct.product ===
              GeneralSalesItems.medtraxBasicService ||
            item.productDetail.fsProduct.product ===
              GeneralSalesItems.additionalUserMonthly
        )
      ) {
        if (productName === GeneralSalesItems.medtraxEnterpriseService) {
          return;
        }
      }
    }

    const targetIndex = cart.findIndex(
      item => item.productDetail.fsProduct.product === productName
    );
    if (targetIndex !== -1) {
      const clone = [...cart];
      clone[targetIndex].quantity += quantity;
      setCart(clone);
    } else {
      let cartItems: ICartItem[] = [
        {
          productDetail: hydratedProductDetails[productName],
          quantity,
        },
      ];
      if (
        productName === FastspringSubscriptionName.medtraxEnterpriseService &&
        isBasicSubscription(isMmcap, isUnlimited)
      ) {
        // add discount item for display purposes.
        cartItems.push({
          productDetail: {
            displayName: 'Prorated Discount',
            description: 'N/A',
            imgPath: 'N/A',
            fsProduct: {
              product: 'prorated-discount',
              pricing: {
                US: {
                  currency: 'USD',
                  price: -priceOfEnterprise * numberOfBasicSubs + proratedCost,
                  display: currencyFormatter.format(
                    -priceOfEnterprise * numberOfBasicSubs + proratedCost
                  ),
                },
              },
            },
          },
          quantity: 1,
        });
      }

      setCart([...cart, ...cartItems]);
    }
  };

  const removeCartItem = (event: MouseEvent<SVGElement>, index: number) => {
    event?.preventDefault();
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  const handleMouseEnter = (index: number): void => {
    setHoverRow(index);
  };

  const handleMouseLeave = (): void => {
    setHoverRow(-1);
  };

  const numberOfAdditonalUserSubs = (): number =>
    subscriptions
      .filter(
        sub =>
          sub.product === FastspringSubscriptionName.additionalUserMonthly &&
          sub.state.toLocaleLowerCase() === FSSubscriptionState.active
      )
      .reduce((sum, sub) => sum + sub.quantity, 0);

  const isProductToRemoveFromUnlimited = (product: string) =>
    product.toLocaleLowerCase() ===
      FastspringSubscriptionName.medtraxBasicService ||
    product.toLocaleLowerCase() ===
      FastspringSubscriptionName.medtraxPlusService ||
    product.toLocaleLowerCase() ===
      FastspringSubscriptionName.additionalUserMonthly;

  const getReleventProducts = (): FSProduct[] | null => {
    if (isMmcap) return mmcapProducts;
    if (isBasicSubscription(isMmcap, isUnlimited)) return nonMmcapProducts;
    if (!isMmcap && isUnlimited) {
      return nonMmcapProducts.filter(
        ({ product }) =>
          FastspringUnlimitedUserItemsArray.includes(
            product.toLocaleLowerCase()
          ) || !isProductToRemoveFromUnlimited(product)
      );
    }
    return null;
  };

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          bottom: '80px',
          right: '80px',
          zIndex: '1000',
        }}
      >
        <div
          style={{
            position: 'relative',
            backgroundColor: '#e3f2fd',
            borderRadius: '200px',
            padding: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '3px 3px 2px #c0c0c0',
          }}
        >
          <a href="/" onClick={event => goToCart(event)}>
            <CiShoppingCart
              style={{
                height: '44px',
                width: '50px',
                position: 'relative',
                top: '4px',
                right: '1px',
              }}
            />
          </a>
          {cart.length ? (
            <div
              className="red white-text bold"
              style={{
                position: 'absolute',
                top: '12px',
                right: '8px',
                height: '16px',
                width: '16px',
                borderRadius: '200px',
              }}
            >
              <p
                className="white-text bold"
                style={{
                  position: 'absolute',
                  fontSize: '.75em',
                  top: '-11px',
                  right: '5.5px',
                }}
              >
                {getCartQuantity()}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {Object.keys(hydratedProductDetails).length ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '20px',
            justifyContent: 'start',
            alignItems: 'stretch',
          }}
        >
          {getReleventProducts()?.map(({ product }) => {
            const currentProduct = hydratedProductDetails[product];

            if (currentProduct) {
              const { imgPath, description, displayName, fsProduct } =
                currentProduct;

              return (
                <div
                  key={product}
                  className="col s4"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                  }}
                >
                  <div
                    className="card"
                    style={{ display: 'flex', padding: '20px' }}
                  >
                    <div
                      className="card-content"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '280px',
                        alignSelf: 'stretch',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignSelf: 'stretch',
                        }}
                      >
                        <div
                          className="row"
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <img
                            src={imgPath}
                            alt={`${displayName}`}
                            style={{
                              aspectRatio: 2 / 1,
                              height: '75%',
                              width: '75%',
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                        <div className="row">
                          <h4 className="center">{displayName}</h4>
                        </div>
                        <div className="row" style={{ marginBottom: '40px' }}>
                          <p style={{ textAlign: 'center', fontSize: '1.2em' }}>
                            {description}
                          </p>
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <h6>
                            {currencyFormatter.format(
                              fsProduct.pricing.US.price || 0
                            )}
                          </h6>
                          <div className="row">
                            <a
                              href="/"
                              onClick={event => previewProduct(event, product)}
                              className="btn-small blue white-text waves-effect waves-light col s12"
                            >
                              Preview Product
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : null}
      <div
        ref={cartModal}
        id="cart-modal"
        className="modal"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="modal-content">
          <div className="row">
            <h4>Cart</h4>
            <div className="row" style={{ marginBottom: '40px' }}>
              <table className="highlight">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th style={{ textAlign: 'right' }}>Line Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.length > 0 ? (
                    cart?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave()}
                      >
                        <td style={{ padding: '2px', textAlign: 'left' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'start',
                              gap: '8px',
                              alignItems: 'center',
                            }}
                          >
                            {item.productDetail?.displayName}
                            {hoverRow === index ? (
                              <MdDeleteOutline
                                onClick={event => removeCartItem(event, index)}
                                style={{ height: '1.5rem', width: 'auto' }}
                                className="red-text"
                              />
                            ) : (
                              <div style={{ width: '22.5px' }} />
                            )}
                          </div>
                        </td>
                        <td style={{ padding: '2px', textAlign: 'left' }}>
                          {item.quantity}
                        </td>
                        <td style={{ padding: '2px', textAlign: 'left' }}>
                          {currencyFormatter.format(
                            item?.productDetail.fsProduct.pricing.US.price || 0
                          )}
                        </td>
                        <td style={{ padding: '2px', textAlign: 'right' }}>
                          {currencyFormatter.format(
                            (item.productDetail?.fsProduct.pricing.US.price ||
                              1) * item.quantity
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td style={{ padding: '2px' }}>Cart is empty</td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ padding: '2px' }}>
                      <b>Total</b>
                    </td>
                    <td
                      colSpan={3}
                      style={{ textAlign: 'right', padding: '2px' }}
                    >
                      <b>{currencyFormatter.format(getCartTotal())}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col s3">
                {cart.length > 0 ? (
                  <a
                    href="/"
                    onClick={event => checkoutCart(event)}
                    className="btn-small blue white-text waves-effect waves-light col s12"
                  >
                    Checkout
                  </a>
                ) : (
                  <a
                    href="/"
                    className="btn-small blue white-text waves-effect waves-light col s12"
                    onClick={event => {
                      event.preventDefault();
                      getCartModal()?.close();
                    }}
                  >
                    Close
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={addModalEnterpriseUpgrade}
        id="add-modal-enterprise-upgrade"
        className="modal"
      >
        <div className="modal-content">
          <div
            className="grid-container"
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <h4 style={{}}>{currentlyViewedProduct?.displayName}</h4>
            <div>Description</div>
            <div>{currentlyViewedProduct?.description}</div>
            <table>
              <thead>
                <tr>
                  <th>Line Item</th>
                  <th>Type</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Line Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Medtrax Basic</td>
                  <td>Cancelation</td>
                  <td>{numberOfBasicSubs}</td>
                  <td>{currencyFormatter.format(0)}</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td>Additional User</td>
                  <td>Cancelation</td>
                  <td>{numberOfAdditonalUserSubs()}</td>
                  <td>{currencyFormatter.format(0)}</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td>Medtrax Enterprise</td>
                  <td>Upgrade</td>
                  <td>{numberOfBasicSubs}</td>
                  <td>{currencyFormatter.format(priceOfEnterprise)}</td>
                  <td>
                    {currencyFormatter.format(
                      priceOfEnterprise * numberOfBasicSubs
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Prorated Discount</td>
                  <td>Discount</td>
                  <td>1</td>
                  <td>
                    {currencyFormatter.format(
                      -priceOfEnterprise * numberOfBasicSubs + proratedCost
                    )}
                  </td>
                  <td>
                    {currencyFormatter.format(
                      -priceOfEnterprise * numberOfBasicSubs + proratedCost
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: 'bold' }}>First Month</td>
                  <td>{currencyFormatter.format(proratedCost)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: 'bold' }}>Monthly</td>
                  <td>
                    {currencyFormatter.format(
                      priceOfEnterprise * numberOfBasicSubs
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: 'flex', gap: '20px' }}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '250px' }}>
                <a
                  href="/"
                  onClick={event => {
                    if (currentlyViewedProduct)
                      addToCart(
                        event,
                        currentlyViewedProduct.fsProduct.product,
                        numberOfBasicSubs
                      );
                  }}
                  className="btn-small blue white-text waves-effect waves-light col s12"
                >
                  Add Upgrade
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={addModal} id="add-modal" className="modal">
        <div className="modal-content">
          <div
            className="grid-container"
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <h4>{currentlyViewedProduct?.displayName}</h4>
            <div>Description</div>
            <div>{currentlyViewedProduct?.description}</div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <div>Price: </div>
              <div>
                {currencyFormatter.format(
                  currentlyViewedProduct?.fsProduct.pricing.US.price || 0
                )}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '250px' }}>
                <a
                  href="/"
                  onClick={event => {
                    if (currentlyViewedProduct)
                      addToCart(
                        event,
                        currentlyViewedProduct.fsProduct.product,
                        productQuantity
                      );
                  }}
                  className="btn-small blue white-text waves-effect waves-light col s12"
                >
                  Add To Cart
                </a>
              </div>
              <div
                className="quantity-selector"
                style={{
                  display: 'flex',
                  marginTop: '4px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <p
                  style={{
                    fontSize: '1rem',
                    position: 'relative',
                    bottom: '4px',
                  }}
                >
                  Quantity:
                </p>
                <a
                  href="/"
                  className="btn-decrement"
                  onClick={event => {
                    event.preventDefault();
                    decrementQuantity();
                  }}
                >
                  <FaRegMinusSquare style={{ fontSize: '1.8rem' }} />
                </a>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '1px',
                  }}
                >
                  <span
                    className="quantity-display"
                    style={{
                      fontSize: '1rem',
                      textAlign: 'center',
                      height: '1.8rem',
                      position: 'relative',
                      bottom: '1px',
                    }}
                  >
                    {productQuantity}
                  </span>
                </div>
                <a
                  href="/"
                  className="btn-increment"
                  onClick={event => {
                    event.preventDefault();
                    incrementQuantity();
                  }}
                >
                  <FaRegPlusSquare style={{ fontSize: '1.8rem' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifySubscription;
