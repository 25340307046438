import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  PiCaretDoubleLeftBold,
  PiCaretDoubleRightBold,
  PiCaretLeftBold,
  PiCaretRightBold,
} from 'react-icons/pi';

interface IProps {
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageSize: number;
  totalItems: number;
}

const Paginator = ({
  pageNumber,
  setPageNumber,
  pageSize,
  totalItems,
}: IProps) => {
  const [pages, setPages] = useState<number[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    if (true || totalItems) {
      const totalPages = Math.ceil(totalItems / pageSize);
      let startNumber = 1;
      const stopPages = totalPages < 7 ? totalPages : 7;
      const pages: number[] = [];

      if (pageNumber > 3) startNumber = pageNumber - 3;
      if (pageNumber > totalPages - 6 && totalPages > 6) startNumber = totalPages - 6;

      for (let i = 0; i < stopPages; i++) pages.push(startNumber + i);
      setTotalPages(totalPages);
      setPages(pages);
    }
  }, [pageNumber, pageSize, totalItems]);

  return totalItems ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifySelf: 'stretch',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        fontSize: '1.2rem'
      }}
    >
      <PiCaretDoubleLeftBold
        style={{
          cursor: 'pointer',
          color: pageNumber > 1 ? '' : 'lightgrey',
        }}
        onClick={() => {
          if (pageNumber > 1) setPageNumber(1);
        }}
      />
      <PiCaretLeftBold
        style={{
          cursor: 'pointer',
          marginRight: '40px',
          color: pageNumber > 1 ? '' : 'lightgrey',
        }}
        onClick={() => {
          if (pageNumber > 1) setPageNumber(prev => prev - 1);
        }}
      />
      {pages?.map(page => (
        <div
          key={page}
          className={page === pageNumber ? 'blue' : ''}
          style={{
            width: '28px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
          }}
        >
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setPageNumber(page)}
            className={page === pageNumber ? 'white-text' : ''}
          >
            {page}
          </span>
        </div>
      ))}
      <PiCaretRightBold
        style={{
          cursor: 'pointer',
          color: pageNumber === totalPages ? 'lightgrey' : '',
          marginLeft: '40px',
        }}
        onClick={() => {
          if (pageNumber < pages[pages.length - 1])
            setPageNumber(prev => prev + 1);
        }}
      />
      <PiCaretDoubleRightBold
        style={{
          cursor: 'pointer',
          color: pageNumber === totalPages ? 'lightgrey' : '',
        }}
        onClick={() => {
          if (pageNumber < totalPages) setPageNumber(totalPages);
        }}
      />
    </div>
  ) : null;
};

export default Paginator;
