import endpoints from '../endpoints.ts';
import api from '../api/index.ts';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FSSubscription } from '../types/fastspringModels.ts';

const useReadAllSubscriptionsById = (
  options?: UseQueryOptions<FSSubscription[] | null>
) =>
  useQuery({
    queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId()],
    queryFn: api.fastspring.read.all.subscriptions.by.userId,
    ...options,
  });

export default {
  useReadAllSubscriptionsById,
};
