import endpoints from '../endpoints.ts';
import { HydratedEpcisEvent, HydratedRecall } from '../types/compoundModels.ts';
import { Toast } from '../types/models.ts';
import { fetch } from './helpers.ts';

export namespace RecallAPI {
  export interface CreateOneDismissedRecall {
    recallId: number | string;
    epcisEventId: number | string;
    notes?: string;
  }
  export interface ReadManySearch {
    pageSize: number;
    pageNumber: number;
    manufacturer?: string;
    medName?: string;
    ndc?: string;
    lotNumber?: string;
    dateReceivedStart?: string;
    dateReceivedEnd?: string;
  }

  export interface ReadManySearchCount {
    manufacturer?: string;
    medName?: string;
    ndc?: string;
    lotNumber?: string;
    dateReceivedStart?: string;
    dateReceivedEnd?: string;
  }

  export type ReadOneActiveRecallMedCountReturn = {
    recallCodeId: number;
    affectedMedCount: number;
  }[];
}

const nrac = {
  create: {
    one: {
      dismissedRecall: async (
        params: RecallAPI.CreateOneDismissedRecall
      ): Promise<Toast | null> => {
        const url = endpoints.recall.create.one.dismissedRecall();
        return fetch<Toast>(url, 'POST', params);
      },
    },
  },
  read: {
    one: {
      by: {
        id: async (
          recallId: number | string
        ): Promise<HydratedRecall | null> => {
          const url = endpoints.recall.read.one.by.id();
          return await fetch<HydratedRecall>(url, 'GET', { recallId });
        },
      },
      activeRecalledMedCount: {
        by: {
          userId:
            async (): Promise<RecallAPI.ReadOneActiveRecallMedCountReturn | null> => {
              const url =
                endpoints.recall.read.one.activeRecalledMedCount.by.userId();
              return await fetch<RecallAPI.ReadOneActiveRecallMedCountReturn>(
                url,
                'GET'
              );
            },
        },
      },
    },
    many: {
      search: async (
        params: RecallAPI.ReadManySearch
      ): Promise<HydratedRecall[] | null> => {
        const url = endpoints.recall.read.many.search();
        return await fetch<HydratedRecall[]>(url, 'GET', params);
      },
      searchCount: async (
        params: RecallAPI.ReadManySearchCount
      ): Promise<{ totalRows: number } | null> => {
        const url = endpoints.recall.read.many.searchCount();
        return await fetch<{ totalRows: number }>(url, 'GET', params);
      },
    },
    all: {
      by: {
        recallCodeIds: (
          recallCodeIds: number[] | string[]
        ): Promise<HydratedRecall[] | null> => {
          const url = endpoints.recall.read.all.by.recallCodeIds();
          return fetch<HydratedRecall[]>(url, 'GET', { recallCodeIds });
        },
      },
      activeRecalls: {
        by: {
          recallId: async (
            recallId: number | string
          ): Promise<HydratedEpcisEvent[] | null> => {
            const url = endpoints.recall.read.all.activeRecalls.by.recallId();
            return await fetch<HydratedEpcisEvent[]>(url, 'GET', { recallId });
          },
          recallCodeIds: async (
            recallCodeIds: number[] | string[]
          ): Promise<HydratedEpcisEvent[] | null> => {
            const url =
              endpoints.recall.read.all.activeRecalls.by.recallCodeIds();
            return await fetch<HydratedEpcisEvent[]>(url, 'GET', {
              recallCodeIds,
            });
          },
        },
      },
    },
  },
  update: {
    one: {
      toggleDismissedRecall: async (
        dismissedRecallId: number | string
      ): Promise<Toast | null> => {
        const url = endpoints.recall.update.one.toggleDismissedRecall();
        return fetch<Toast>(url, 'PUT', { dismissedRecallId });
      },
    },
  },
};

export default nrac;
