import Axios from 'axios';
import {
  useEffect,
  useRef,
  useState,
  Fragment,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { logout, toast } from '../../func';
import dataMatrixCodec from '../../utilities/dataMatrixCodec';
import styled from '@emotion/styled';
import moment from 'moment';
import { BsThreeDots } from 'react-icons/bs';
import { IoMdCheckmarkCircleOutline, IoMdReturnRight } from 'react-icons/io';
import M from 'materialize-css';
import ContextMenu from '../../components/ContextMenu.tsx';

const { NODE_ENV } = process.env;

const OrderScan = () => {
  const { documentId } = useParams();
  const medGroupTriggers = useRef([]);

  const ref = useRef({
    abortController: new AbortController(),
    sgtinTimer: null,
    sgtin: '',
    selectedMedVocabularyId: 0,
    receiveMedDebounce: null,
  });
  const getAuthData = () => JSON.parse(localStorage.getItem('auth_data'));

  const [loading, setLoading] = useState(false);
  const [meds, setMeds] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sgtin, setSgtin] = useState('');
  const [groupSeparatorArray, setGroupSeparatorArray] = useState([]);
  const [selectedMed, setSelectedMed] = useState(null);

  const loadMeds = useCallback(() => {
    setLoading(true);
    Axios.get('/api/v1/order/read/all/meds/by/documentid', {
      params: {
        ...getAuthData(),
        documentId,
      },
      signal: ref.current.abortController.signal,
    })
      .then(result => setMeds(result.data))
      .catch(logout)
      .finally(() => setLoading(false));
  }, [documentId]);

  const focusInput = () => {
    const sgtinElement = document.querySelector('#sgtin');
    setTimeout(() => {
      sgtinElement.select();
    }, 600);
  };

  const receiveMed = () => {
    clearTimeout(ref.current.receiveMedDebounce);
    ref.current.receiveMedDebounce = setTimeout(() => {
      setLoading(true);
      let medArray = [];
      const { sgtin } = ref.current;

      tableData.forEach(
        td => (medArray = [...medArray, ...td.meds, ...td.hierarchy])
      );
      const foundMed = medArray.find(
        med => med.sgtin.split('.').pop() === sgtin
      );

      if (!foundMed) {
        toast({
          payload: {
            msg: 'Med not found on this order',
            class: 'orange white-text',
          },
        });
        setLoading(false);
        focusInput();
        return;
      }

      Axios.post('/api/v1/med/update/receive/many/by/serialnumber', {
        ...getAuthData(),
        serialNumber: foundMed.sgtin,
        documentId,
      })
        .then(result => {
          toast(result.data);
          loadMeds();
          setSgtin('');
        })
        .catch(logout)
        .finally(() => {
          setLoading(false);
          focusInput();
        });
    }, 750);
  };

  const receiveMedGroup = () => {
    setLoading(true);
    Axios.put('/api/v1/med/update/receive/many/by/epcismedvocabularyid', {
      ...getAuthData(),
      epcisMedVocabularyId: ref.current.selectedMedVocabularyId,
      documentId,
    })
      .then(result => {
        toast(result.data);
        loadMeds();
        setSgtin('');
      })
      .catch(logout)
      .finally(() => {
        setLoading(false);
        focusInput();
      });
  };

  useEffect(() => {
    const abortController = ref.current.abortController;
    loadMeds();
    return () =>
      NODE_ENV === 'production' ? abortController.abort() : undefined;
  }, [loadMeds, ref.current.abortController]);

  useLayoutEffect(() => {
    M.Modal.init(document.querySelector('#med-detail-modal'));
    return () =>
      document.querySelectorAll('.modal').forEach(el => {
        if (el) {
          M.Modal.getInstance(el).destroy();
        }
      });
  }, []);

  const onChange = ({ target: { value } }) => setSgtin(value);

  const onKeyDown = e => {
    const { keyCode, ctrlKey } = e;

    if (keyCode === 221 && ctrlKey) {
      const arr = groupSeparatorArray;
      arr.push(sgtin?.length);
      setGroupSeparatorArray(arr);
    }

    if (keyCode === 13) {
      setTimeout(() => {
        receiveMed();
      }, 100);
    }
  };

  const parseBarcode = barcode => {
    Object.keys(barcode).forEach(key => {
      switch (key) {
        case dataMatrixCodec.SERIAL_NUMBER:
          setSgtin(barcode[key]);
          const el = document.querySelector('#sgtin');
          el.dispatchEvent(
            new KeyboardEvent('keydown', {
              key: 'Enter',
              code: 'Enter',
              keyCode: 13,
              which: 13,
              bubbles: true,
            })
          );
          break;
        case dataMatrixCodec.GLOBAL_TRADE_ITEM_NUMBER:
        case dataMatrixCodec.EXPIRATION_DATE_YYMMDD:
        case dataMatrixCodec.BATCH_OR_LOT_NUMBER:
          break;
        default:
          console.log(`Unknown property: ${key}, value: ${barcode[key]}`);
      }

      document.querySelector('#sgtin')?.focus();
    });
  };

  useEffect(() => {
    ref.current.sgtin = sgtin;
    if (ref.current.sgtinTimer) clearTimeout(ref.current.sgtinTimer);

    ref.current.sgtinTimer = setTimeout(() => {
      if (groupSeparatorArray?.length) {
        try {
          let temp = '';
          groupSeparatorArray?.forEach(
            (gs, index) =>
              (temp = [
                sgtin.slice(0, gs + index),
                sgtin.slice(gs + index),
              ].join('{{GS}}'))
          );

          setSgtin(temp.replaceAll(/{{GS}}/g, ''));
          setGroupSeparatorArray([]);

          const barcode = dataMatrixCodec.decode(temp, '{{GS}}');
          parseBarcode(barcode);
        } catch (err) {
          console.log(err);
          focusInput();
        }
      }
    }, 250);
  }, [sgtin, groupSeparatorArray]);

  useEffect(() => {
    const tableData = [];
    const drillDown = (arr, hierarchy = []) => {
      arr.forEach(item => {
        const newhierarchy = [...hierarchy]; // Create a copy of the hierarchy array
        if (item.children) {
          const hierarchyData = JSON.parse(JSON.stringify(item));
          delete hierarchyData.children;

          newhierarchy.push(hierarchyData); // Push to the new array
          drillDown(item.children, newhierarchy); // Pass the new array to the recursive call
        } else {
          const group = tableData.find(
            group => group.epcisMedVocabularyId === item.epcisMedVocabularyId
          );
          group
            ? group.meds.push(item)
            : tableData.push({
                epcisMedVocabularyId: item.epcisMedVocabularyId,
                hierarchy: newhierarchy, // Use the new array here
                meds: [item],
              });
        }
      });
    };

    if (meds) {
      drillDown(meds);
      setTableData(tableData);
    }
  }, [meds]);

  const Table = useMemo(
    () => styled.table`
      tr:has(+ tr.table-drawer[data-open='open']) {
        border: 2px solid black;
      }

      tr.table-drawer {
        display: none;

        &[data-open='open'] {
          display: table-row;
          border-bottom: 2px solid black;

          & > td {
            border-left: 2px solid black;
            border-right: 2px solid black;
          }
        }
      }
    `,
    []
  );

  const toggleTableDrawer = (event, id) => {
    const td = document.querySelector(`#table-top-level-context-${id}`);
    if (td.contains(event.target)) {
      event.stopPropagation();
      ref.current.selectedMedVocabularyId = id;
      return;
    }

    const drawer = document.getElementById(`table-drawer-${id}`);

    if (drawer) {
      const status = drawer.getAttribute('data-open');
      drawer.setAttribute('data-open', status === 'open' ? 'closed' : 'open');
    }
  };

  const showModal = (med, hierarchy) => {
    setSelectedMed({ ...med, hierarchy });
    const el = document.querySelector('#med-detail-modal');
    const modal = M.Modal.getInstance(el);
    modal.open();
  };

  const receiveEntireOrder = event => {
    event?.preventDefault();
    if (
      window.confirm(
        'Are you sure you would like to mark every item on this order as received?'
      )
    ) {
      setLoading(true);
      Axios.put('/api/v1/med/update/receive/all/by/documentId', {
        ...getAuthData(),
        documentId,
      })
        .then(result => {
          toast(result.data);
          loadMeds();
          setSgtin('');
        })
        .catch(logout)
        .finally(() => {
          setLoading(false);
          focusInput();
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="input-field col s8 m4">
          <input
            id="sgtin"
            type="text"
            value={sgtin}
            onChange={onChange}
            onKeyDown={onKeyDown}
            tabIndex="1"
            disabled={loading}
          />
          <label htmlFor="sgtin">Serial #</label>
        </div>
        <div className="input-field col s4 m2 offset-m6">
          <a href="/" onClick={receiveEntireOrder}>
            Receive All
          </a>
        </div>
      </div>
      <div className="row">
        <Table>
          <thead>
            <tr>
              <th style={{ padding: '4px' }}>Name</th>
              <th style={{ padding: '4px' }}>NDC</th>
              <th style={{ padding: '4px' }}>Type</th>
              <th style={{ padding: '4px' }}>Description</th>
              <th style={{ padding: '4px', textAlign: 'right' }}>Received</th>
              <th style={{ padding: '4px', textAlign: 'right' }}>Total</th>
              <th style={{ padding: '4px' }}></th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((medGroup, index) => {
              const med = medGroup.meds[0];
              const receivedCount = medGroup.meds?.filter(
                med => med.receivedById
              ).length;
              const totalCount = medGroup.meds?.length;
              const isReceived = receivedCount === totalCount;
              return (
                <Fragment key={medGroup.epcisMedVocabularyId}>
                  <tr
                    style={{ cursor: 'pointer' }}
                    className={`bold`}
                    onClick={event =>
                      toggleTableDrawer(event, medGroup.epcisMedVocabularyId)
                    }
                  >
                    <td style={{ padding: '8px', borderRadius: '0px' }}>
                      {med?.epcisMedVocabulary?.regulatedProductName}
                    </td>
                    <td style={{ padding: '8px', borderRadius: '0px' }}>
                      {med?.epcisMedVocabulary?.additionalTradeItemId}
                    </td>
                    <td style={{ padding: '8px', borderRadius: '0px' }}>
                      {med?.epcisMedVocabulary?.dosageFormType === 'Unknown'
                        ? ''
                        : med?.epcisMedVocabulary?.dosageFormType || ''}
                    </td>
                    <td style={{ padding: '8px', borderRadius: '0px' }}>
                      {med?.epcisMedVocabulary?.strengthDescription}
                    </td>
                    <td
                      style={{
                        padding: '0px',
                        borderRadius: '0px',
                        textAlign: 'right',
                      }}
                    >
                      {isReceived ? (
                        <IoMdCheckmarkCircleOutline
                          className="green-text"
                          style={{
                            fontSize: '24px',
                            position: 'relative',
                            top: '3px',
                            right: '12px',
                          }}
                        />
                      ) : (
                        receivedCount
                      )}
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        borderRadius: '0px',
                        textAlign: 'right',
                      }}
                    >
                      {totalCount}
                    </td>
                    <td ref={el => (medGroupTriggers.current[index] = el)}>
                      <BsThreeDots
                        id={`table-top-level-context-${medGroup.epcisMedVocabularyId}`}
                        className="right"
                      />
                    </td>
                  </tr>
                  <tr
                    id={`table-drawer-${medGroup.epcisMedVocabularyId}`}
                    className="table-drawer"
                    data-open="closed"
                  >
                    <td
                      style={{ padding: '2px', borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <table style={{ margin: '0px', padding: '0px' }}>
                        <thead>
                          <tr>
                            <th style={{ padding: '0px' }}></th>
                            <th style={{ padding: '0px' }}>Serial #</th>
                            <th style={{ padding: '0px' }}>Date Received</th>
                            <th style={{ padding: '0px' }}>Received By</th>
                            <th style={{ padding: '0px' }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {medGroup.meds?.map((med, index) => (
                            <tr
                              key={med.sgtin}
                              style={
                                index === medGroup.meds.length - 1
                                  ? { border: 'none' }
                                  : {}
                              }
                            >
                              <td style={{ padding: '2px' }}></td>
                              <td style={{ padding: '2px' }}>
                                {med.sgtin.split('.').pop()}
                              </td>
                              <td style={{ padding: '2px' }}>
                                {med.datetimeReceived
                                  ? moment(med.datetimeReceived).format(
                                      'MM/DD/YY'
                                    )
                                  : ''}
                              </td>
                              <td style={{ padding: '2px' }}>
                                {med.receivedById
                                  ? `${med.receivedBy.firstName} ${med.receivedBy.lastName}`
                                  : ''}
                              </td>
                              <td style={{ padding: '2px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    gap: '10%',
                                  }}
                                >
                                  {!med.receivedById && (
                                    <a
                                      href="/"
                                      onClick={event => {
                                        event.preventDefault();
                                        const serialNumber = med.sgtin
                                          .split('.')
                                          .pop();
                                        ref.current.sgtin = serialNumber;
                                        setSgtin(serialNumber);
                                        receiveMed();
                                      }}
                                    >
                                      Receive Med
                                    </a>
                                  )}
                                  <a
                                    href="/"
                                    onClick={event => {
                                      event.preventDefault();
                                      showModal(med, medGroup.hierarchy);
                                    }}
                                  >
                                    View Detail
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div id="med-detail-modal" className="modal">
        {selectedMed ? (
          <div className="modal-content">
            <div className="row">
              <h5 className="bolder">
                <i>{selectedMed.sgtin}</i>
              </h5>
            </div>
            <div className="divider" />
            <div
              className="row"
              style={{
                fontSize: '1.25rem',
                padding: '2rem',
                marginBottom: '60px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>Med Name:</b>
                  <span>{`${
                    selectedMed.epcisMedVocabulary.regulatedProductName
                  } ${
                    selectedMed.epcisMedVocabulary.strengthDescription
                      ? ` - ${selectedMed.epcisMedVocabulary.strengthDescription}`
                      : ''
                  }`}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>Med Serial Prefix:</b>
                  <span>
                    {selectedMed.epcisMedVocabulary.sgtin.replace('.*', '')}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>Item Serial Number:</b>
                  <span>
                    {selectedMed.sgtin
                      .replace(
                        selectedMed.epcisMedVocabulary.sgtin.replace('*', ''),
                        ''
                      )
                      .replace('.', '')}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>NDC:</b>
                  <span>
                    {selectedMed.epcisMedVocabulary.additionalTradeItemId || ''}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>Dosage Form Type:</b>
                  <span>
                    {selectedMed.epcisMedVocabulary.dosageFormType === 'Unknown'
                      ? ''
                      : selectedMed.epcisMedVocabulary.dosageFormType || ''}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>Date Received:</b>
                  <span>
                    {selectedMed.datetimeReceived
                      ? moment(selectedMed.datetimeReceived).format('MM/DD/YY')
                      : ''}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '12px',
                  }}
                >
                  <b>Received By:</b>
                  <span>
                    {selectedMed.receivedById
                      ? selectedMed.receivedBy.firstName +
                        ' ' +
                        selectedMed.receivedBy.lastName
                      : ''}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row" style={{ marginBottom: '5px' }}>
                <h5 className="bold">Packaging Detail</h5>
              </div>
              <div className="divider" style={{ marginBottom: '15px' }} />
              <div
                className="row"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignContent: 'start',
                  fontSize: '1.1em',
                }}
              >
                {selectedMed.hierarchy && selectedMed.hierarchy.length ? (
                  <>
                    {selectedMed.hierarchy?.map((parentMed, index) => (
                      <div
                        key={parentMed.sgtin}
                        style={{
                          paddingLeft: 2 * index + 'rem',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                          gap: '12px',
                        }}
                      >
                        {index !== 0 ? <IoMdReturnRight /> : null}
                        <span className="bold">
                          {parentMed.epcisMedVocabulary.regulatedProductName} -{' '}
                          {parentMed.sgtin}
                        </span>
                      </div>
                    ))}
                    <div
                      style={{
                        paddingLeft: 2 * selectedMed.hierarchy.length + 'rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: '12px',
                      }}
                    >
                      <IoMdReturnRight />
                      <span className="bold">
                        <i>This Med</i>
                      </span>
                    </div>
                  </>
                ) : (
                  <p>This med was not part of a larger package</p>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ContextMenu
        triggerRef={medGroupTriggers}
        options={{ 'Receive All': receiveMedGroup }}
      />
    </div>
  );
};

export default OrderScan;
