import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import queryHooks from '../../common/queryHooks/index.ts';
import Paginator from '../../components/Paginator.tsx';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isNracSubscribed: boolean;
}

const RecallSearch = ({ isNracSubscribed }: IProps) => {
  const navigate = useNavigate();

  const initFilters = () => ({
    dateReceivedStart: '',
    dateReceivedEnd: '',
    manufacturer: '',
    medName: '',
    ndc: '',
    lotNumber: '',
  });

  const pageSize = useMemo(() => 50, []);
  const [filters, setFilters] = useState(initFilters);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const {
    data: recalls,
    refetch,
    isLoading: isFetching,
  } = queryHooks.recall.useReadManySearch(
    {
      ...filters,
      pageNumber,
      pageSize,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: pageNumber > 1,
    }
  );

  const { data: totalRows } = queryHooks.recall.useReadManySearchCount(
    filters,
    { enabled: Boolean(recalls?.length) }
  );

  const onChange = ({ target: { id, value } }: ChangeEvent<HTMLInputElement>) =>
    setFilters(prev => ({ ...prev, [id]: value }));

  useEffect(() => {
    if (!showAdvancedSearch) {
      setFilters(prev => ({
        ...prev,
        dateReceivedEnd: '',
        dateReceivedStart: '',
      }));
    }
  }, [showAdvancedSearch]);

  const searchRecalls = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setPageNumber(1);
    refetch();
  };

  const goToDetailPage = (recallId: number) =>
    navigate(`/recalls/detail/${recallId}`);

  useLayoutEffect(() => {
    const enterListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') refetch();
    };

    document.querySelectorAll('.input-field>input').forEach(el => {
      (el as any).addEventListener('keydown', enterListener);
    });

    return () => {
      document.querySelectorAll('.input-field>input').forEach(el => {
        (el as any).removeEventListener('keydown', enterListener);
      });
    };
  }, [refetch]);

  if (!isNracSubscribed) return null;
  return (
    <div>
      <div className="row" style={{ marginBottom: '0px', marginTop: '8px' }}>
        <a
          href="/"
          className="right"
          onClick={event => {
            event.preventDefault();
            setShowAdvancedSearch(prev => !prev);
          }}
        >
          {showAdvancedSearch ? 'hide ' : 'show '} advanced search options
        </a>
      </div>
      <div className="row">
        <div className="row">
          <div className="input-field col s12 m3">
            <input
              id="manufacturer"
              type="text"
              value={filters.manufacturer}
              onChange={onChange}
            />
            <label htmlFor="manufacturer">Manufacturer Name</label>
          </div>
          <div className="input-field col s12 m3">
            <input
              id="medName"
              type="text"
              value={filters.medName}
              onChange={onChange}
            />
            <label htmlFor="medName">Med Name</label>
          </div>
          <div className="input-field col s12 m3">
            <input
              id="ndc"
              type="text"
              value={filters.ndc}
              onChange={onChange}
            />
            <label htmlFor="ndc">NDC</label>
          </div>
          <div className="input-field col 12 m3">
            <input
              id="lotNumber"
              type="text"
              value={filters.lotNumber}
              onChange={onChange}
            />
            <label htmlFor="lotNumber">Lot Number</label>
          </div>
        </div>
        {showAdvancedSearch ? (
          <>
            <div className="input-field col s12 m3">
              <input
                id="dateReceivedStart"
                type="date"
                value={filters.dateReceivedStart}
                onChange={onChange}
              />
              <label>Date Recalled Range Start</label>
            </div>
            <div className="input-field col s12 m3">
              <input
                id="dateReceivedEnd"
                type="date"
                value={filters.dateReceivedEnd}
                onChange={onChange}
              />
              <label htmlFor="dateReceivedEnd">Date Recalled Range End</label>
            </div>
          </>
        ) : null}
        <div className="input-field col s12 m3 right">
          <a
            href="/"
            className={`btn-small blue white-text waves-effect waves-light col s12 ${
              isFetching ? 'disabled' : ''
            }`}
            onClick={searchRecalls}
          >
            Search All Recalls
          </a>
        </div>
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Med Name</th>
              <th>Manufacturer</th>
              <th>NDC(s)</th>
            </tr>
          </thead>
          <tbody>
            {recalls?.map(({ id, product, manufacturer, recallCodes }) => (
              <tr
                key={id}
                style={{ cursor: 'pointer' }}
                onClick={() => goToDetailPage(id)}
              >
                <td style={{ padding: '4px' }}>{product}</td>
                <td style={{ padding: '4px' }}>{manufacturer}</td>
                <td style={{ padding: '4px' }}>
                  {recallCodes
                    .map(({ ndcWithDashes }) => ndcWithDashes)
                    .join(', ')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Paginator
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          totalItems={totalRows?.totalRows || 0}
        />
      </div>
    </div>
  );
};

export default RecallSearch;
