// These types represent a best guess.
// At the time of writing Fastspring is rewriting their documentation.
// Keep in mind that these types may define things that exist
// or not define things that do. - KD

export interface FSContact {
  first: string;
  last: string;
  email: string;
  company: string;
  phone: string;
  subscribed: boolean;
}

export interface FSAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  region: string;
  regionCustom: string;
  postalCode: number;
  company: string;
}

export interface FSLookup {
  global: string;
  custom: string;
}

export interface FSPayment {
  methods: number;
  active: number;
}

export interface FSCharge {
  currency: string;
  total: number;
  payoutCurrency: string;
  totalInPayoutCurrency: number;
  status: string;
  order: string;
  orderReference: string;
  subscription: string;
  timestamp: number;
  timestampValue: number;
  timestampInSeconds: number;
  timestampDisplay: string;
  timestampDisplayISO8601: string;
}

export interface FSAccount {
  id: string;
  account: string;
  action: string;
  contact: FSContact;
  address: FSAddress;
  language: string;
  country: string;
  lookup: FSLookup;
  url: string;
  payment: FSPayment;
  orders: string[];
  subscriptions: string[];
  charges: FSCharge[];
  subscribed: boolean;
  result: string;
  taxExemptionData: unknown;
}

export interface FSAddon {
  product: string;
  sku: string;
  display: string;
  quantity: 1;
  price: number;
  priceDisplay: string;
  priceInPayoutCurrency: number;
  priceInPayoutCurrencyDisplay: string;
  subtotal: number;
  subtotalDisplay: string;
  subtotalInPayoutCurrencyDisplay: string;
  discounts: unknown[];
}

export interface FSReminder {
  intervalUnit: string;
  intervalLenght: number;
  total?: number;
  sent?: number;
}

export interface FSCancellationSetting {
  cancellation: string;
  intervalUnit: string;
  intervalLength: number;
}

export interface FSSubscription {
  id: string;
  subscription: string;
  active: boolean;
  state: string;
  isSubscriptionEligibleForPauseByBuyer: boolean;
  isPauseScheduled: boolean;
  changed: number;
  changedValue: number;
  changedInSeconds: number;
  changedDisplay: string;
  live: boolean;
  declineReason: string;
  paymentMethod: string;
  expiry: string;
  cardEnding: number;
  cardType: string;
  currency: string;
  account: string;
  product: string;
  sku: string;
  display: string;
  quantity: number;
  adhoc: boolean;
  autoRenew: boolean;
  price: number;
  priceDisplay: string;
  priceInPayoutCurrency: number;
  priceInPayoutCurrencyDisplay: string;
  discount: number;
  discountDisplay: string;
  discountInPayoutCurrency: number;
  discountInPayoutCurrencyDisplay: string;
  subtotal: number;
  subtotalDisplay: string;
  subtotalInPayoutCurrency: number;
  subtotalInPayoutCurrencyDisplay: string;
  attributes: { [key: string]: string }[];
  discountDuration: number;
  next: number;
  nextInSeconds: number;
  nextDisplay: string;
  end: number;
  endValue: number;
  endInSeconds: number;
  endDisplay: string;
  canceledDate: number;
  canceledDateValue: number;
  canceledDateInSeconds: number;
  canceledDateDisplay: string;
  deactivationDate: number;
  deactivationDateValue: number;
  deactivationDateInSeconds: number;
  deactivationDateDisplay: string;
  sequence: number;
  periods: number;
  remainingPeriods: number;
  begin: number;
  beginValue: number;
  beginInSeconds: number;
  beginDisplay: string;
  intervalUnit: string;
  intervalLength: number;
  nextChargeCurrency: string;
  nextChargeDate: number;
  nextChargeDateValue: number;
  nextChargeDateInSeconds: number;
  nextChargeDateDisplay: string;
  nextChargePreTax: number;
  nextChargePreTaxDisplay: string;
  nextChargeTotalInPayoutCurrency: number;
  nextChargeTotalInPayoutCurrencyDisplay: string;
  nextNotificationType: string;
  nextNotificationDate: number;
  nextNotificationDateValue: number;
  nextNotificationDateInSeconds: number;
  nextNotificationDateDisplay: string;
  trialReminder: FSReminder;
  paymentReminder: FSReminder;
  paymentOverdue: FSReminder;
  cancellationSetting: FSCancellationSetting;
  addons: FSAddon[];
  discounts: {
    discountPath: string;
    discountDuration: number;
    percentValue: number;
  }[];
}

export interface FSPricing {
  currency: string | undefined;
  price: number | undefined;
  display: string | undefined;
}

export interface FSProduct {
  product: string;
  pricing: { US: FSPricing };
}

export enum FSIntervalENUM {
  month = 'month',
  year = 'year',
}

export enum FSDiscountENUM {
  percent = 'percent',
}

export enum FSRenewENUM {
  auto = 'auto',
}
export interface FSItem {
  product: string;
  quantity: number;
  pricing?: {
    price: {
      USD: number;
    };
    renew?: FSRenewENUM;
    interval?: FSIntervalENUM;
    intervalLength?: number;
    discountType?: FSDiscountENUM;
    discountDuration?: number;
    quantityBehavior?: string;
    quantityDiscounts?: {
      [quantity: string]: number; // string (numnber of  discounts): amount/percentage of discount
    };
  };
}




