import React from 'react';
import NotSubscribed from './NotSubscribed.tsx';
import ActiveRecallList from './ActiveRecallList.tsx';

interface IProps {
  isNracSubscribed: boolean;
  isFetching: boolean;
}

const Recalls = ({ isNracSubscribed, isFetching }: IProps) => {
  return (
    <div>
      {isFetching ? (
        <div style={{ marginTop: '40px' }} className="progress">
          <div className="indeterminate" />
        </div>
      ) : (
        <div>
          {isNracSubscribed ? (
            <ActiveRecallList isNracSubscribed={isNracSubscribed} />
          ) : (
            <NotSubscribed />
          )}
        </div>
      )}
    </div>
  );
};

export default Recalls;
